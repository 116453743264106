import React, { useEffect, useReducer, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import orderBy from "lodash/orderBy";
import { message, Table } from "antd";
import { SortButton } from "components/ui";
import { ServiceTypes } from "@ebs-platform/components";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { TableFooter, TableHeader } from "components/ui/Table";
import { SortDirectionType } from "components/ui/SortButton";
import { Users } from "components/svg";
import { initialState, reducer, ReducerActionType } from "../store";
import UserProfile from "./UserProfile";
import "./UserDetails.css";
import ActionsColumn from "components/ActionsColumn";
import { OrganizationUser } from "libs/interfaces/users";

interface UserDetailsTeam {
  id: number;
  name: string;
}

const UserDetails: React.FC = () => {
  const params: { userId?: string } = useParams<{ userId?: string }>();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const [state, dispatch] = useReducer(reducer, initialState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filteredTeams, setFilteredTeams] = useState<any[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>("asc");

  // Fetch user by id
  const fetchUserById = useCallback(
    async (userId: number) => {
      try {
        if (userId) {
          const fetchedUser = await fetcher<OrganizationUser>(
            `/organizations/user/${userId}/`
          );

          dispatch({ type: ReducerActionType.FETCH_USER, user: fetchedUser });
          setFilteredTeams(fetchedUser.teams);
        }
      } catch (e) {
        message.error(e.message);
      }
    },
    [fetcher]
  );

  // Remove user from team
  const removeUserFromTeam = async (teamId: number): Promise<void> => {
    try {
      const userId = Number(params.userId);
      await fetcher(`/organizations/user/team/${teamId}/${userId}/`, {
        request: { method: "DELETE" }
      });

      // Fetch users teams after delete
      fetchUserById(userId);
    } catch (e) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    fetchUserById(Number(params.userId));
  }, [params.userId, fetchUserById]);

  // Handle teams search
  const handleTeamsSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;

    if (state.user) {
      const teams = state.user.teams.filter((team: UserDetailsTeam) =>
        team.name.toLowerCase().includes(value.toLowerCase())
      );

      setFilteredTeams(teams);
    }
  };

  // Handle sort direction
  const handleSortDirection = (direction: SortDirectionType): void => {
    setSortDirection(direction);
  };

  useEffect(() => {
    const orderedArray = orderBy(filteredTeams, ["name"], [sortDirection]);
    setFilteredTeams(orderedArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDirection]);

  const teamList: UserDetailsTeam[] = filteredTeams.map(
    (team: UserDetailsTeam) => {
      return {
        id: team.id,
        key: team.id,
        name: team.name
      };
    }
  );

  return (
    <div className="user-details__wrapper">
      <UserProfile user={state.user} fetchUser={fetchUserById} />
      <Table
        className="table table-small"
        dataSource={teamList}
        title={(items: UserDetailsTeam[]): React.ReactNode => (
          <TableHeader
            title="Teams"
            count={items.length}
            onChangeSearch={handleTeamsSearch}
          >
            <SortButton onChangeDirection={handleSortDirection} />
          </TableHeader>
        )}
        footer={(items: UserDetailsTeam[]): React.ReactNode => (
          <TableFooter count={items.length} />
        )}
      >
        <Table.Column
          title="Team"
          dataIndex="name"
          key="name"
          render={(teamName: string): React.ReactNode => {
            return (
              <div className="table-team-profile">
                <Users />
                {teamName}
              </div>
            );
          }}
        />

        <ActionsColumn
          title="Actions"
          key="actions"
          render={(item: UserDetailsTeam): React.ReactNode => {
            return (
              <span
                onClick={removeUserFromTeam.bind(null, item.id)}
                className="action-link"
              >
                Remove from team
              </span>
            );
          }}
          className="table-cell-actions"
          width={165}
        />
      </Table>
    </div>
  );
};

export default UserDetails;
