import { RadioChangeEvent } from "antd/es/radio";
import RadioButtons from "./RadioButtons";

export interface RadioButton {
  value: string;
  label: string;
}

export interface RadioButtonsProps {
  defaultValue: string;
  items: RadioButton[];
  onChange?: (e: RadioChangeEvent) => void;
}

export default RadioButtons;
