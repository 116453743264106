import React from "react";
import { Form, Row, Col, Button, Input, message, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";
import { ValidationError } from "@ebs-platform/components/esm/errors";
import { useURLParams } from "hooks";
import { formItemLayout, formTailLayout } from "../utils";
import OrganizationLogo from "../LoginForm/OrganizationLogo";
import { useHistory } from "react-router-dom";
import { removeSpaces } from "../../../utils/strings";

const Invitation: React.FC<FormComponentProps> = ({ form }) => {
  const params = useURLParams();
  const history = useHistory();
  const { getFieldDecorator } = form;
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  const token = params.get("token");
  getFieldDecorator("invite_token", { initialValue: token });

  const formSubmitHandler = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    form.validateFields(async (errors, data) => {
      if (!errors) {
        try {
          await fetcher("/users/invite-accept/", { data });

          message.success("Registration was completed successfully.");
          history.push("/authentication/login");
        } catch (error) {
          if (error instanceof ValidationError) {
            if (error.fields.length) {
              form.setFields(error.fields);
            }
          }

          message.error(error.message);
        }
      }
    });
  };

  return (
    <Form
      onSubmit={formSubmitHandler}
      className="authentication-form"
      {...formItemLayout}
    >
      <Row>
        <Col {...formTailLayout}>
          <div className="authentication-form__title-wrapper">
            <OrganizationLogo
              message={(name): string =>
                `Complete your invitation in ${name} account`
              }
            />

            {!token && (
              <Alert
                type="error"
                message="Invite token is not set!"
                description="Please check your URL and try again."
              />
            )}
          </div>
        </Col>
      </Row>

      <Form.Item label="First name">
        {getFieldDecorator("first_name", {
          rules: [{ required: true, message: "First name is required." }]
        })(<Input disabled={!token} autoComplete="off" />)}
      </Form.Item>

      <Form.Item label="Last name">
        {getFieldDecorator("last_name", {
          rules: [{ required: true, message: "Last name is required." }]
        })(<Input disabled={!token} autoComplete="off" />)}
      </Form.Item>

      <Form.Item label="Password">
        {getFieldDecorator("password", {
          rules: [{ required: true, message: "Enter your account password." }]
        })(<Input.Password disabled={!token} autoComplete="off" onChange={(ev): void => {
          ev.target.value = removeSpaces(ev.target.value);
        }} />)}
      </Form.Item>

      <Row>
        <Col {...formTailLayout}>
          <Button disabled={!token} type="primary" htmlType="submit">
            Onboard
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({ name: "InvitationForm" })(Invitation);
