import React, { useCallback, useLayoutEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";

import { Form, Input, Row, Col, Modal, Alert, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";

import useSearchParam from "hooks/useURLParams";

import {
  formItemLayout,
  formTailLayout,
  FieldsValidationError
} from "../utils";

interface ReducerState {
  loading: boolean;
  errorMsg: string | null;
}

interface ReducerAction {
  type: "SET_LOADING" | "REMOVE_LOADING" | "SET_ERROR_MSG";
  errorMsg: string | null;
}

const initialState: ReducerState = {
  loading: false,
  errorMsg: null
};

const reducer = (_: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case "REMOVE_LOADING":
      return { loading: false, errorMsg: null };
    case "SET_ERROR_MSG":
      return { loading: false, errorMsg: action.errorMsg };
    case "SET_LOADING":
      return { loading: true, errorMsg: null };
    default:
      throw new Error("Unexpected action type");
  }
};

const ResetPasswordConfirmForm: React.FC<FormComponentProps> = ({ form }) => {
  const history = useHistory();
  const fetcher = useFetcher(ServiceTypes.SSO);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;
  const urlSearchParams = useSearchParam();
  const token = urlSearchParams.get("token");

  useLayoutEffect(() => {
    if (!token) {
      history.replace("/authentication/login");
    }
  }, [token, history]);

  getFieldDecorator("token", {
    initialValue: token
  });

  const formSubmitHandler = useCallback(
    (e: React.FormEvent<Element>) => {
      e.preventDefault();

      form.validateFieldsAndScroll(async (err, data) => {
        if (!err) {
          try {
            const sucess = await fetcher<boolean>(`/account/confirm-restore/`, {
              data
            });

            if (sucess) {
              Modal.success({
                title: `Your password was successfully restored.`,
                onOk: () => history.replace("/authentication/login")
              });
            }
          } catch (error) {
            if (error instanceof TypeError) {
              dispatch({ type: "REMOVE_LOADING", errorMsg: null });
              Modal.error({
                title:
                  "Password restore failed, are you connected to the internet?"
              });
            } else if (error instanceof FieldsValidationError) {
              dispatch({ type: "REMOVE_LOADING", errorMsg: null });
              form.setFields(error.fields);
            } else {
              dispatch({ type: "SET_ERROR_MSG", errorMsg: error.message });
            }
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher]
  );

  return (
    <Form
      {...formItemLayout}
      form={form}
      className="authentication-form"
      onSubmit={formSubmitHandler}
    >
      <Row>
        <Col {...formTailLayout}>
          <div className="authentication-form__title-wrapper">
            <div className="title-wrapper__title">Restore password confirm</div>

            <div className="title-wrapper__subtitle">
              Here you can restore your password
            </div>
            {state.errorMsg && <Alert type="error" message={state.errorMsg} />}
          </div>
        </Col>
      </Row>

      <Form.Item label="New password">
        {getFieldDecorator("password", {
          rules: [
            {
              pattern:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#%^])[A-Za-z\d@$!%*?&#%^]{8,}$/,
              message:
                "Password must be at least 8 characters long and contain at least one number, one uppercase letter and one special character."
            },
            { required: true, message: "Password is required." }
          ]
        })(<Input.Password />)}
      </Form.Item>
      <Form.Item label="Repeat password">
        {getFieldDecorator("confirm_password", {
          rules: [
            {
              validator: (_, value, callback): void => {
                if (value && value !== form.getFieldValue("password")) {
                  callback("Passwords do not match.");
                } else {
                  callback();
                }
              }
            },
            { required: true, message: "Repeat password is required." }
          ],
          validateTrigger: "onBlur"
        })(<Input.Password />)}
      </Form.Item>
      <Row>
        <Col {...formTailLayout}>
          <Button type="primary" htmlType="submit" onClick={formSubmitHandler}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({ name: "ResetPasswordConfirmForm" })(
  ResetPasswordConfirmForm
);
