import React from "react";
import { Link } from "react-router-dom";
import { User } from "components/UsersPage/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderUserLink = (action: any, item: User): React.ReactNode => {
  const restItems = item.teams.slice(1, item.teams.length).length;
  const firstItem = item.teams.length > 0 ? item.teams[0].name : null;

  return (
    <>
      {firstItem}
      {restItems > 1 && (
        <Link to={`/users/${item.user.id}`} style={{ marginLeft: "5px" }}>
          +{restItems}
        </Link>
      )}
    </>
  );
};

// Sorting
export const sortItems = [
  {
    key: "first_name",
    title: "By name",
  },
  {
    key: "date_joined",
    title: "By date",
  },
];
