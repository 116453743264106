import React from "react";
import { Link } from "react-router-dom";
import { Arrow } from "components/svg";

export interface CardExtraProps {
  title?: string;
  link?: string;
  icon?: React.ReactNode;
}

const CardExtra: React.FC<CardExtraProps> = ({ title, link, icon }) => {
  const titleIcon = (
    <>
      {title} {icon && icon}
    </>
  );

  return (
    <div className="card__extra">
      {/* { : null} */}
      {link ? <Link to={{ pathname: link }}>{titleIcon}</Link> : titleIcon}
    </div>
  );
};

CardExtra.defaultProps = {
  icon: <Arrow />,
  link: "#",
  title: "View All"
};

export default CardExtra;
