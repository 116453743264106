import React from "react";
import { Typography } from "antd";
import classNames from "classnames";
import "./PageTitle.css";

interface PageTitleProps {
  title: string;
  className?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, className }) => {
  return (
    <Typography.Title level={4} className={classNames("page-title", className)}>
      {title}
    </Typography.Title>
  );
};

export default PageTitle;
