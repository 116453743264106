import React, { useContext, useEffect, useCallback, useReducer } from "react";
import { Button, message } from "antd";
import { uniqBy, uniq } from "lodash";
import { useParams, useHistory } from "react-router-dom";
import { ServiceTypes } from "@ebs-platform/components";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import {
  SubscribedApplication,
  ApplicationAccess
} from "libs/interfaces/applications";
import { IconButton } from "components/ui";
import { FormWizardContext } from "components/ui/FormWizard";
import PermissionItem from "./PermissionItem";
import "./PermissionsUserForm.css";
import { initialState, ReducerActionType, reducer } from "./store";

const PermissionsUserForm: React.FC = () => {
  const history = useHistory();
  const { userId }: { userId?: string } = useParams<{ userId?: string }>();
  const organization = useOrganization();
  const wizardContext = useContext(FormWizardContext);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  const [state, dispatch] = useReducer(reducer, initialState);

  // Fetch subscribed applications
  const fetchSubscribedApps = useCallback(async () => {
    try {
      if (organization) {
        const subscribedApplications = await fetcher<SubscribedApplication[]>(
          `/applications/${organization.id}/`
        );

        dispatch({
          type: ReducerActionType.SET_SUBSCRIBED_APPLICATIONS,
          subscribedApplications
        });
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [organization, fetcher]);

  // Dispatch applications access
  const setApplicationAccess = (
    applicationsAccess: ApplicationAccess[]
  ): void => {
    dispatch({
      type: ReducerActionType.SET_APPLICATIONS_ACCESS,
      applicationsAccess
    });
  };

  // Dispatch users id
  const setUsersId = (usersId: number[]): void => {
    dispatch({
      type: ReducerActionType.SET_USERS_ID,
      usersId: uniq([...state.usersId, ...usersId])
    });
  };

  // Fetch user access for applications
  const fetchUserApplicationsAccess = useCallback(async () => {
    try {
      if (organization && userId) {
        const applicationsAccess: ApplicationAccess[] = await fetcher(
          `/applications/access/${organization.id}/${userId}/`
        );

        if (applicationsAccess) {
          setApplicationAccess(applicationsAccess);
        }
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [organization, userId, fetcher]);

  useEffect(
    () => {
      fetchSubscribedApps();

      // Edit access
      if (userId) {
        // Set userId from params
        setUsersId([Number(userId)]);
        fetchUserApplicationsAccess();
      }

      if (wizardContext.data && Array.isArray(wizardContext.data)) {
        setUsersId(wizardContext.data);
      }
    },
    // setUsersId function update the state, cannot be set to deps list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      organization,
      fetchSubscribedApps,
      fetchUserApplicationsAccess,
      userId,
      wizardContext
    ]
  );

  // Submit access changes
  const handleSubmit = useCallback(async () => {
    if (organization) {
      const applications = state.applicationsAccess.map((app) => ({
        role: app.role,
        id: app.application
      }));

      const data = {
        users: state.usersId,
        applications
      };

      try {
        await fetcher(`/applications/access/${organization.id}/`, { data });
        message.success("Permissions was successfully changed");
        history.push(`/people/users/1`);
      } catch (error) {
        const defaultMessage =
          "Error during updating user's permissions. Please try again";
        const errorMsg = error.message || defaultMessage;
        message.error(errorMsg);
      }
    }
  }, [history, organization, state, fetcher]);

  const handleCancel = (): void => {
    history.push(`/people/users/1`);
  };

  // On change application's role
  const handleChange = (
    appId: number,
    role: string | null = null,
    isActive: boolean
  ): void => {
    const applicationRole = {
      application: appId,
      role
    };
    let applicationsAccess;

    if (isActive) {
      applicationsAccess = uniqBy(
        [applicationRole, ...state.applicationsAccess],
        "application"
      );
    } else {
      applicationsAccess = state.applicationsAccess.filter(
        (app) => app.application !== appId
      );
    }

    setApplicationAccess(applicationsAccess);
  };

  return (
    <div className="user-permissions">
      <div className="permission-list applications">
        {state.subscribedApplications.map((app: SubscribedApplication) => (
          <PermissionItem
            key={app.id}
            item={app}
            access={state.applicationsAccess}
            onChange={handleChange}
          />
        ))}
      </div>

      <div className="users-page__buttons">
        <Button onClick={handleCancel}>Cancel</Button>
        <IconButton text="Submit adding" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default PermissionsUserForm;
