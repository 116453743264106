/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import {
  Bootstrap,
  ServiceTypes,
  BootstrapConfig
} from "@ebs-platform/components";
import { routes } from "libs/router";
import SessionGuard from "./SessionGuard";

const config: BootstrapConfig = {
  [ServiceTypes.SSO]: { baseUrl: process.env.REACT_APP_BASE_SSO! },
  [ServiceTypes.ORGANIZATION]: { baseUrl: process.env.REACT_APP_BASE_API! },
  [ServiceTypes.CONNECT]: { baseUrl: process.env.REACT_APP_BASE_CONNECT! },
  [ServiceTypes.NOTIFICATIONS]: { baseUrl: process.env.REACT_APP_BASE_NOTIFICATION! }
};

const publicRoutes = routes.filter(({ isPublic }) => isPublic);

const AppSession: React.FC = () => {
  const handleInvalidSession = useCallback((): void => {
    // @TODO Implement invalid session flow
  }, []);

  return (
    <Bootstrap config={config} onInvalidSession={handleInvalidSession}>
      <Switch>
        {publicRoutes.map(({ name, ...params }) => (
          <Route key={name} {...params} />
        ))}

        <SessionGuard />
      </Switch>
    </Bootstrap>
  );
};

export default AppSession;
