import React from "react";
import { Avatar } from "antd";
import { Edit, Trash } from "components/svg";
import { getNameAcronym } from "utils/strings";
import "./ProfileImage.css";

interface ProfileImageProps {
  username?: string;
  photo?: string;
  showActions?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

const ProfileImage: React.FC<ProfileImageProps> = ({
  username,
  photo,
  showActions,
  onEdit,
  onDelete
}) => {
  const acronym = getNameAcronym(username);

  return (
    <div className="profile-image">
      <Avatar src={photo} shape="square" size={72}>
        {acronym}
      </Avatar>

      {showActions && (
        <div className="profile-image__actions">
          <span className="profile-image__action-item" onClick={onEdit}>
            <Edit />
          </span>
          <span className="profile-image__action-item" onClick={onDelete}>
            <Trash />
          </span>
        </div>
      )}
    </div>
  );
};

ProfileImage.defaultProps = {
  username: "UN",
  showActions: false
};

export default ProfileImage;
