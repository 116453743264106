import React from "react";
import classnames from "classnames";
import { Card as CardAntd } from "antd";
import CardExtra, { CardExtraProps } from "./CardExtra";
import CardTitle from "./CardTitle";
import "./Card.css";

interface CardProps {
  title: string;
  icon?: React.ReactNode;
  extra?: CardExtraProps | boolean;
  className?: string;
  filter?: React.ReactNode;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  children,
  className,
  title,
  icon,
  filter,
  extra
}) => {
  const cardClassNames = classnames("card", className);
  const extraProps = typeof extra === "boolean" ? {} : extra;

  return (
    <CardAntd
      className={cardClassNames}
      title={<CardTitle title={title} icon={icon} filter={filter} />}
      extra={<CardExtra {...extraProps} />}
    >
      {children}
    </CardAntd>
  );
};

Card.defaultProps = {
  extra: true
};

export default Card;
