import React, { useEffect, useState } from "react";
import { Form, Input, Divider, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { formItemLayout } from "./utils";
import LogoUpload from "./LogoUpload";
import { Button, ServiceTypes } from "@ebs-platform/components";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { ValidationError } from "@ebs-platform/components/esm/errors";

import "./UserProfileForm.css";
import { getUrlSubDomain } from "utils/strings";

interface UserInterface {
  first_name: string;
  last_name: string;
  phone: string;
  position: string;
  photo: string;
}

const ProfileForm: React.FC<FormComponentProps> = ({ form }) => {
  const { getFieldsValue, getFieldDecorator } = form;
  const [user, setUser] = useState<UserInterface>();
  const [isLoad, setIsLoad] = useState(false);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const subdomain = getUrlSubDomain();

  useEffect(() => {
    fetcher(`/users/login/user/`, {
      request: {
        method: "POST",
        body: JSON.stringify({ domain_name: subdomain })
      }
    })
      .then((response) => {
        setUser(response.user);
        setIsLoad(true);
      })
      .catch((e) => {
        message.error("Something is wrong");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher]);

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();

    const data = getFieldsValue();
    try {
      await fetcher(`/users/edit/`, {
        request: { method: "PUT", body: JSON.stringify(data) }
      });
      message.success("Profile updated");
    } catch (error) {
      if (error instanceof ValidationError) {
        return form.setFields(error.fields);
      }
      message.error(error.message);
    }
  };

  return (
    <div className="container container--dashed">
      {isLoad && (
        <Form id="user-profile-form" {...formItemLayout} onSubmit={submitForm}>
          <div className="company-form">
            <Form.Item>
              {getFieldDecorator("photo")(
                <LogoUpload title="Profile photo" imageUrl={user!.photo} />
              )}
            </Form.Item>
            <Divider />
            <div>
              <Form.Item
                className="company-form__field-label"
                label="First name:"
              >
                {getFieldDecorator("first_name", {
                  initialValue: user?.first_name
                })(<Input placeholder="Text here" />)}
              </Form.Item>
              <Form.Item
                className="company-form__field-label"
                label="Last name:"
              >
                {getFieldDecorator("last_name", {
                  initialValue: user?.last_name
                })(<Input placeholder="Text here" />)}
              </Form.Item>

              <Form.Item className="company-form__field-label" label="Phone:">
                {getFieldDecorator("phone", {
                  initialValue: user?.phone
                })(<Input placeholder="Text here" />)}
              </Form.Item>

              <Form.Item
                className="company-form__field-label"
                label="Job title:"
              >
                {getFieldDecorator("position", {
                  initialValue: user?.position
                })(<Input placeholder="Text here" />)}
              </Form.Item>
            </div>
          </div>
          <div className="container__footer">
            <Button
              type="primary"
              className="btn--save-form"
              form="user-profile-form"
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Form.create({ name: "profile" })(ProfileForm);
