import React from "react";
import IconButton from "./IconButton";
import { ButtonProps } from "antd/es/button";

export interface IconButtonProps extends ButtonProps {
  svgIcon?: React.ReactNode;
  text: string;

  /**
   * link - is used for react router
   * href - is button prop
   */
  link?: string;
}

export default IconButton;
