import React from "react";
import { SubscribedApplication } from "libs/interfaces/applications";

interface Context {
  subscribedApplications: SubscribedApplication[];
  addSubscribedApplication: (
    subscribedApplication: SubscribedApplication | null
  ) => void;
  deleteSubscribedApplication: (token: string) => void;
  toggleModal: () => void;
}

const ApplicationContext = React.createContext<Context>({
  subscribedApplications: [],
  addSubscribedApplication: () => null,
  deleteSubscribedApplication: () => null,
  toggleModal: () => null
});

export default ApplicationContext;
