import React from "react";
import { Input } from "antd";
import "./TableHeader.css";

interface TableHeader {
  title?: string;
  count?: string | number;
  children?: React.ReactNode;
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TableHeader: React.FC<TableHeader> = ({
  title,
  count,
  children,
  onChangeSearch
}) => {
  const rowsCount = count ? `(${count})` : "";

  return (
    <div className="table-header">
      <h5 className="table-header__title">{`${title} ${rowsCount}`}</h5>
      <div className="table-header__actions">
        <Input.Search
          className="search-input"
          placeholder="Search"
          onChange={onChangeSearch}
        />
        {children}
      </div>
    </div>
  );
};

TableHeader.defaultProps = {
  title: "Table Header"
};

export default TableHeader;
