import { useLocation } from "react-router-dom";

const useSearchParam = (): URLSearchParams => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return params;
};

export default useSearchParam;
