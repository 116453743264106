import React from "react";

const Arrow: React.FC = () => (
  <svg width="1em" height="1em" viewBox="0 0 16 13">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M10.308,0.500 L9.261,1.604 L13.166,5.720 L-0.000,5.720 L-0.000,7.280 L13.166,7.280 L9.261,11.396 L10.308,12.500 L16.000,6.500 L10.308,0.500 Z"
    />
  </svg>
);

export default Arrow;
