import React from "react";
import classNames from "classnames";
import { Row, Col, Input } from "antd";
import { PageTitle } from "components/ui";
import "./PageHeader.css";

interface PageHeaderProps {
  title: string;
  className?: string;
  defaultValue?: string;
  onChangeSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  className,
  defaultValue,
  onChangeSearch,
  children
}) => {
  return (
    <div className={classNames("page-header", className)}>
      <Row type="flex" align="middle">
        <Col xs={24} lg={10}>
          <div className="page-header__search-box">
            <PageTitle title={title} className="page-header__title" />
            <Input.Search
              className="search-input"
              placeholder="Search"
              defaultValue={defaultValue}
              onChange={onChangeSearch}
            />
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <div className="page-header__buttons">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default PageHeader;
