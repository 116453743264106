import React from "react";

const User: React.FC = () => (
  <svg width="12px" height="12px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M6.000,1.425 C6.870,1.425 7.575,2.130 7.575,3.000 C7.575,3.870 6.870,4.575 6.000,4.575 C5.130,4.575 4.425,3.870 4.425,3.000 C4.425,2.130 5.130,1.425 6.000,1.425 M6.000,8.175 C8.231,8.175 10.575,9.266 10.575,9.750 L10.575,10.575 L1.425,10.575 L1.425,9.750 C1.425,9.266 3.769,8.175 6.000,8.175 M6.000,-0.000 C4.342,-0.000 3.000,1.342 3.000,3.000 C3.000,4.654 4.342,6.000 6.000,6.000 C7.657,6.000 9.000,4.654 9.000,3.000 C9.000,1.342 7.657,-0.000 6.000,-0.000 ZM6.000,6.750 C4.001,6.750 -0.000,7.751 -0.000,9.750 L-0.000,12.000 L12.000,12.000 L12.000,9.750 C12.000,7.751 7.999,6.750 6.000,6.750 Z"
    />
  </svg>
);

export default User;
