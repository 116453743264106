import React from "react";

const SortDescend: React.FC = () => (
  <svg width="16px" height="12px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M6.666,2.661 L6.666,1.331 L16.000,1.331 L16.000,2.661 L6.666,2.661 ZM2.195,11.782 L-0.000,9.591 L0.943,8.650 L2.000,9.705 L2.000,-0.000 L3.333,-0.000 L3.333,9.705 L4.390,8.649 L5.333,9.591 L3.138,11.782 C2.878,12.041 2.456,12.041 2.195,11.782 ZM14.000,5.323 L6.666,5.323 L6.666,3.992 L14.000,3.992 L14.000,5.323 ZM12.000,7.984 L6.666,7.984 L6.666,6.653 L12.000,6.653 L12.000,7.984 ZM10.000,10.645 L6.666,10.645 L6.666,9.315 L10.000,9.315 L10.000,10.645 Z"
    />
  </svg>
);

export default SortDescend;
