import React, { useMemo } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import routes from "./routes";
import { useOrganization } from "@ebs-platform/components/esm/hooks";

/**
 * `PrivateRoutes` will render all private routers only if
 * there is a valid session detect
 *
 * Otherwise, this component will redirect user to login path
 * to reauthenticate.
 */
const PrivateRoutes: React.FC = () => {
  const organization = useOrganization();

  const privateRoutes = useMemo(() => {
    return routes.filter(({ isPublic }) => !isPublic);
  }, []);

  if (!organization) {
    return <Redirect to="/authentication/login" />;
  }

  return (
    <Switch>
      {privateRoutes.map((route) => {
        const { name, ...props } = route;

        return <Route key={name} {...props} />;
      })}
    </Switch>
  );
};

export default PrivateRoutes;
