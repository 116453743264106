import React from "react";

interface UsersProps {
  size?: number;
}

const Users: React.FC<UsersProps> = ({ size }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 16"
    style={{ fontSize: `${size}px` }}
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M16.500,16.000 L16.500,14.222 L20.167,14.222 L20.167,12.444 C20.167,11.464 19.346,10.667 18.333,10.667 L16.500,10.667 L16.500,8.889 L18.333,8.889 C20.358,8.889 22.000,10.483 22.000,12.444 L22.000,16.000 L16.500,16.000 ZM14.667,7.111 C13.999,7.111 13.380,6.925 12.840,6.621 L13.753,5.088 C14.024,5.240 14.332,5.333 14.666,5.333 C15.679,5.333 16.500,4.538 16.500,3.555 C16.500,2.574 15.679,1.778 14.667,1.778 C14.333,1.778 14.023,1.871 13.754,2.022 L12.840,0.490 C13.380,0.187 13.999,-0.000 14.667,-0.000 C16.691,-0.000 18.333,1.592 18.333,3.555 C18.333,5.519 16.691,7.111 14.667,7.111 ZM14.667,12.444 L14.667,16.000 L0.000,16.000 L0.000,12.444 C0.000,10.481 1.642,8.889 3.667,8.889 L11.000,8.889 C13.025,8.889 14.667,10.483 14.667,12.444 ZM12.833,12.444 C12.833,11.464 12.013,10.667 11.000,10.667 L3.667,10.667 C2.654,10.667 1.833,11.464 1.833,12.444 L1.833,14.222 L12.833,14.222 L12.833,12.444 ZM7.333,7.111 C5.309,7.111 3.667,5.519 3.667,3.555 C3.667,1.592 5.309,-0.000 7.333,-0.000 C9.358,-0.000 11.000,1.592 11.000,3.555 C11.000,5.519 9.358,7.111 7.333,7.111 ZM7.333,1.778 C6.321,1.778 5.500,2.574 5.500,3.555 C5.500,4.538 6.321,5.333 7.333,5.333 C8.346,5.333 9.167,4.538 9.167,3.555 C9.167,2.574 8.346,1.778 7.333,1.778 Z"
    />
  </svg>
);

Users.defaultProps = {
  size: 16
};

export default Users;
