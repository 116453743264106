import {
  SubscribedApplication,
  ApplicationAccess
} from "libs/interfaces/applications";

export interface PermissionsState {
  subscribedApplications: SubscribedApplication[];
  applicationsAccess: ApplicationAccess[];
  usersId: number[];
}

export enum ReducerActionType {
  SET_SUBSCRIBED_APPLICATIONS,
  SET_APPLICATIONS_ACCESS,
  SET_USERS_ID
}

export interface ReducerAction {
  type: ReducerActionType;
  subscribedApplications?: SubscribedApplication[];
  applicationsAccess?: ApplicationAccess[];
  usersId?: number[];
}

export const initialState: PermissionsState = {
  subscribedApplications: [],
  applicationsAccess: [],
  usersId: []
};

export const reducer = (
  state: PermissionsState,
  action: ReducerAction
): PermissionsState => {
  switch (action.type) {
    case ReducerActionType.SET_SUBSCRIBED_APPLICATIONS: {
      if (!action.subscribedApplications) {
        throw new Error("Subscribed applications array missing");
      }

      return {
        ...state,
        subscribedApplications: action.subscribedApplications
      };
    }

    case ReducerActionType.SET_APPLICATIONS_ACCESS: {
      if (!action.applicationsAccess) {
        throw new Error("Applications access array missing");
      }

      return {
        ...state,
        applicationsAccess: action.applicationsAccess
      };
    }

    case ReducerActionType.SET_USERS_ID: {
      if (!action.usersId) {
        throw new Error("Users ids array missing");
      }

      return {
        ...state,
        usersId: action.usersId
      };
    }

    default:
      throw new Error("Unexpected action type");
  }
};
