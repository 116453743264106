import React from "react";

const Close: React.FC = () => (
  <svg width="12px" height="12px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M0.909,9.394 L4.303,6.000 L0.909,2.606 L2.606,0.909 L6.000,4.303 L9.394,0.909 L11.091,2.606 L7.697,6.000 L11.091,9.394 L9.394,11.091 L6.000,7.697 L2.606,11.091 L0.909,9.394 Z"
    />
  </svg>
);

export default Close;
