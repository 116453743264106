import React, { useEffect } from "react";
import { Button } from "antd";

export interface ActionsProps {
  onSave?: () => void;
  onCancel?: () => void;
}

/**
 * `Actions` Component
 *
 * This component can create a series of buttons.
 * It is used in page header, and to return `Save/Cancel`
 * button collection.
 *
 * Depends on props received, it will display certain button
 * by function that need to be called.
 *
 *
 * @example
 *
 * <Button onSave={() => void} />
 *
 */
const Actions: React.FC<ActionsProps> = ({ onSave, onCancel }) => {
  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent): void => {
      const { ctrlKey, key } = event;

      if (ctrlKey && key === "Enter" && onSave) {
        onSave();
      } else if (key === "Escape" && onCancel) {
        onCancel();
      }
    };

    if (onSave || onCancel) {
      window.addEventListener("keydown", handleUserKeyPress);
    }

    return (): void => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [onSave, onCancel]);

  return (
    <>
      {onCancel ? (
        <Button onClick={onCancel}>
          Cancel <sup style={{ color: "red" }}>Esc</sup>
        </Button>
      ) : null}
      {onSave ? (
        <Button onClick={onSave} type="primary">
          Save <sup>Ctrl+Enter</sup>
        </Button>
      ) : null}
    </>
  );
};

export default Actions;
