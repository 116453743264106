import React, { useEffect, useReducer, useState, useCallback } from "react";
import { message } from "antd";
import { RadioChangeEvent } from "antd/es/radio";
import * as queryString from "query-string";
import { Route, Switch } from "react-router-dom";
import { UsersData } from "libs/interfaces/users";
import { ServiceTypes } from "@ebs-platform/components";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { PageHeader, RadioButtons, SortButton } from "components/ui";
import { SortDirectionType } from "components/ui/SortButton";
import UsersTable from "./UsersTable";
import { initialState, reducer, ReducerActionType } from "./store";
import AddUserButton from "./AddUserButton";
import SyncUserButton from "./SyncUserButton";

// Filter By Status
const filterItems = [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Online",
    value: "online"
  },
  {
    label: "Offline",
    value: "offline"
  }
];

// Sorting
const sortItems = [
  {
    key: "first_name",
    title: "By name"
  },
  {
    key: "date_joined",
    title: "By date"
  }
];

const UsersTableContainer: React.FC = () => {
  const organization = useOrganization();
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  // Sort, filter and search
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirectionType>("asc");
  const [filterBy, setFilterBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingSync, setLoadingSync] = useState(false);

  const handleSort = (sortKey: string): void => {
    setSortBy(sortKey);
  };

  const handleSortDirection = (direction: SortDirectionType): void => {
    setSortDirection(direction);
  };

  const handleFilter = (e: RadioChangeEvent): void => {
    setFilterBy(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.currentTarget.value);
  };

  // Fetch organization users
  const fetchOrganizationUsers = useCallback(async () => {
    try {
      const params = {
        search: searchTerm,
        order_by: sortBy,
        order_direction: sortDirection,
        status: filterBy,
        page: pageNumber
      };

      if (organization) {
        const query = queryString.stringify(params);
        const usersData = await fetcher<UsersData>(
          `/organizations/users/${organization.id}/?${query}`
        );

        if (usersData !== null) {
          dispatch({
            type: ReducerActionType.FETCH_USERS,
            usersData
          });
        }
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [
    sortBy,
    sortDirection,
    filterBy,
    searchTerm,
    pageNumber,
    organization,
    fetcher
  ]);

  useEffect(() => {
    fetchOrganizationUsers();
  }, [fetchOrganizationUsers]);

  // Handle pagination
  const handlePaginationChange = (page: number): void => {
    setPageNumber(page);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const syncActive = async () => {
    try {
      setLoadingSync(true);

      if (organization) {
        await fetcher(`/users/sync-active-directory/${organization.id}/`, {
          request: { method: "POST" }
        })
          .then((response) => {
            message.success(`${response.users_synced} users has been synced`);

            setLoadingSync(false);
          })
          .catch((e) => {
            message.error("Sync failed");
          });
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  return (
    <>
      <PageHeader title="My People" onChangeSearch={handleSearch}>
        <RadioButtons
          defaultValue="all"
          items={filterItems}
          onChange={handleFilter}
        />

        <SortButton
          options={sortItems}
          onChange={handleSort}
          onChangeDirection={handleSortDirection}
        />

        {state.usersData?.allow_ad_sync && (
          <SyncUserButton onSyncButton={syncActive} loading={loadingSync} />
        )}

        <AddUserButton />
      </PageHeader>
      <Switch>
        <Route path={"/people/users/:pageNumber"}>
          <UsersTable
            usersData={state.usersData}
            fetchUsers={fetchOrganizationUsers}
            onChangePagination={handlePaginationChange}
          />
        </Route>
      </Switch>
    </>
  );
};

export default UsersTableContainer;
