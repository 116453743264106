import UserSelect from "./UserSelect";

export interface OptionItem {
  id?: number;
  title?: string;
  description?: string;
  photo?: string;
  noPhotoTitle?: string;
  className?: string;
}

export default UserSelect;
