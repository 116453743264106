import React from "react";
import "./LogoUpload.css";
import Upload from "./Upload";
import LogoPlaceholder from "./images/LogoPlaceholder.png";

export interface LogoUploadProps {
  onChange: (imageUrl?: string) => void;
  title?: string;
  imageUrl?: string;
}

interface LogoUploadState {
  imageUrl?: string;
}

class LogoUpload extends React.PureComponent<LogoUploadProps, LogoUploadState> {
  public static defaultProps: LogoUploadProps = {
    title: "Upload logo",
    onChange: () => {
      /* do nothing */
    }
  };

  constructor(props: LogoUploadProps) {
    super(props);
    this.state = { imageUrl: props.imageUrl };

    this.setImageUrl = this.setImageUrl.bind(this);
  }

  public componentDidUpdate(
    prevProps: LogoUploadProps,
    prevState: LogoUploadState
  ): void {
    if (prevState !== this.state) {
      this.props.onChange(this.state.imageUrl);
    }
  }

  private setImageUrl(fileUrl: string): void {
    this.setState({ imageUrl: fileUrl });
  }

  // tslint:disable-next-line: member-ordering
  public render(): React.ReactNode {
    const { title } = this.props;
    const { imageUrl } = this.state;

    return (
      <div className="logo-upload">
        <div className="image">
          <img src={imageUrl || LogoPlaceholder} alt={title} />
        </div>

        <div>
          <div className="title">{title}</div>
          <div className="description">
            Maximum file size 20 mb, accepted formats: PNG, JPG
          </div>
          <Upload onChange={this.setImageUrl} image>
            {imageUrl ? "Change image" : "Upload image"}
          </Upload>
        </div>
      </div>
    );
  }
}

export default LogoUpload;
