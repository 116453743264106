import { OrganizationUser, TeamData, Team, UsersData } from "libs/interfaces/users";

export interface UsersState {
  user: OrganizationUser | null;
  usersData: UsersData | null;
  team: Team | null;
  teams: TeamData[];
}

export enum ReducerActionType {
  FETCH_USERS,
  FETCH_USER,
  FETCH_TEAMS,
  FETCH_TEAM
}

export interface ReducerAction {
  type: ReducerActionType;
  user?: OrganizationUser;
  usersData?: UsersData;
  team?: Team;
  teams?: TeamData[];
}

export const initialState: UsersState = {
  user: null,
  usersData: null,
  team: null,
  teams: []
};

export const reducer = (
  state: UsersState,
  action: ReducerAction
): UsersState => {
  switch (action.type) {
    case ReducerActionType.FETCH_USERS: {
      if (!action.usersData) {
        throw new Error("Organization users array missing");
      }

      return {
        ...state,
        usersData: action.usersData
      };
    }

    case ReducerActionType.FETCH_USER: {
      if (!action.user) {
        throw new Error("Organization user missing");
      }

      return {
        ...state,
        user: action.user
      };
    }

    case ReducerActionType.FETCH_TEAMS: {
      if (!action.teams) {
        throw new Error("Organization teams array missing");
      }

      return {
        ...state,
        teams: action.teams
      };
    }

    case ReducerActionType.FETCH_TEAM: {
      if (!action.team) {
        throw new Error("Organization team missing");
      }

      return {
        ...state,
        team: action.team
      };
    }

    default:
      throw new Error("Unexpected action type");
  }
};
