import React from "react";
import { List } from "antd";
import ProfileImage from "./ProfileImage";
import { IconButton } from "components/ui";
import "./Profile.css";
import { IconButtonProps } from "../IconButton";

interface ProfileProps {
  title?: string;
  description?: React.ReactNode;
  avatarIcon?: React.ReactNode;
}

interface ProfileType extends React.FC<ProfileProps> {
  Action: React.FC<IconButtonProps>;
}

/**
 * Componente to display profile info.
 * 
 * Use Profile.Action as children components to add actions to the profile.
 */
const Profile: ProfileType = ({
  title,
  description,
  avatarIcon,
  children
}) => {
  const avatar = avatarIcon ? avatarIcon : <ProfileImage username={title} />;

  return (
    <div className="profile-wrapper">
      <List.Item>
        <List.Item.Meta
          avatar={avatar}
          title={title}
          description={description}
        />
      </List.Item>
      <div className="profile-actions">
        {children}
      </div>
    </div>
  );
};

Profile.defaultProps = {
  title: "Profile Title"
};

Profile.Action = IconButton;

export default Profile;
