import React from "react";
import { FormWizard } from "components/ui";
import UserForm from "../UserForm";
import PermissionsUserForm from "../PermissionsUserForm";

const steps = [
  {
    stepTitle: "Add member",
    component: <UserForm />
  },
  {
    title: "Set permission for our member",
    stepTitle: "Set permissions",
    component: <PermissionsUserForm />
  }
];

const UserAddWizard: React.FC = () => {
  return <FormWizard steps={steps} />;
};

export default UserAddWizard;
