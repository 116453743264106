import React, { useContext } from "react";
import { Col, Avatar } from "antd";
import { OrganizationUserRole } from "@ebs-platform/common";
import withPermissions from "hocs/withPermissions";

import ApplicationContext from "../context";

import "./ApplicationItem.scss";

const ApplicationItemCreatable: React.FC = () => {
  const { toggleModal } = useContext(ApplicationContext);

  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={6} onClick={toggleModal}>
      <div className="application__item application__large application__item--creatable">
        <Avatar
          size={62}
          icon="plus-circle"
          className="application__item__avatar"
        />
        <h4 className="application__item__title">Add new application</h4>
        <p className="application__item__description">
          Discover apps repository
        </p>
      </div>
    </Col>
  );
};

export default withPermissions([OrganizationUserRole.ADMIN])(
  ApplicationItemCreatable
);
