import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ProfileItem, { ProfileItemProps } from "./ProfileItem";
import "./ProfileList.css";

interface ProfileListProps {
  items?: ProfileItemProps[];
  itemsShowed?: number;
  align?: string;
  className?: string;
}

const ProfileList: React.FC<ProfileListProps> = ({
  items = [],
  itemsShowed,
  align,
  className
}) => {
  const profileItems = items.slice(0, itemsShowed);
  const restItems =
    itemsShowed && items.length > itemsShowed ? items.length - itemsShowed : 0;

  return (
    <div
      className={classNames(
        "profile-list",
        `profile-list--${align}`,
        className
      )}
    >
      {profileItems.map((item: ProfileItemProps, idx: number) => {
        return <ProfileItem key={idx} item={item} />;
      })}
      {restItems !== 0 && (
        <Link to="#" className="profile-list__rest">
          {`+${restItems}`}
        </Link>
      )}
    </div>
  );
};

ProfileList.defaultProps = {
  itemsShowed: 3,
  align: "right"
};

export default ProfileList;
