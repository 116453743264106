/* eslint-disable react/display-name */
import React from "react";
import { Link } from "react-router-dom";
import { NormalizedTeam } from "components/UsersPage/utils";
import { Users } from "components/svg";

export const renderTeamLink = (
  name: string,
  team: NormalizedTeam
): React.ReactNode => {
  return (
    <div className="table-team-profile">
      <Users />
      <Link to={`/teams/${team.id}`}>{name}</Link>
    </div>
  );
};

export const renderUserLink = (
  users: string,
  team: NormalizedTeam
): React.ReactNode => <Link to={`/teams/${team.id}`}>{users}</Link>;
