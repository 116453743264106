import React from "react";

const Chevron: React.FC = () => (
  <svg width="7px" height="12px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M1.097,0.000 L0.011,1.104 L4.061,5.220 L4.061,6.780 L0.011,10.897 L1.097,12.000 L7.000,6.000 L1.097,0.000 Z"
    />
  </svg>
);

export default Chevron;
