import { useURLParams } from "hooks";

const useSearchParam = (name: string): string | undefined => {
  const params = useURLParams();
  const query = params.get(name);

  return query ?? undefined;
};

export default useSearchParam;
