import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { Dots } from "components/svg";
import { ActionOption } from "./index";
import "./ColumnActions.css";

interface ColumnActionsProps {
  options?: ActionOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item?: any;
}

// Column Actions Dropdown
const renderActions = (
  options: ActionOption[] | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any
): React.ReactNode => {
  const handleClick = (optionItem: ActionOption): void =>
    optionItem.onClick && optionItem.onClick(item);

  return (
    <Menu className="column-actions">
      <Menu.Item className="column-actions__header">ACTIONS</Menu.Item>

      {options &&
        options.map((option: ActionOption, idx: number) => {
          return (
            <Menu.Item
              key={idx}
              className="column-actions__item"
              onClick={handleClick.bind(null, option)}
            >
              {option.name}
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

const ColumnActions: React.FC<ColumnActionsProps> = ({ options, item }) => {
  const columnDropdown = renderActions(options, item);

  return (
    <Dropdown
      overlay={columnDropdown}
      placement="bottomRight"
      trigger={["click"]}
      align={{ offset: [7, 5] }}
    >
      <Link className="ant-dropdown-link" to="#">
        <Dots />
      </Link>
    </Dropdown>
  );
};

export default ColumnActions;
