import React from "react";

const AddUsers: React.FC = () => (
  <svg width="16px" height="16px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M13.425,13.425 L13.425,16.000 L11.893,16.000 L11.893,13.425 L9.318,13.425 L9.318,11.893 L11.893,11.893 L11.893,9.318 L13.425,9.318 L13.425,11.893 L16.000,11.893 L16.000,13.425 L13.425,13.425 ZM12.957,8.329 C12.202,7.660 11.235,7.263 10.232,7.210 C10.148,7.215 10.065,7.218 9.984,7.218 C9.829,7.218 9.673,7.208 9.519,7.188 C9.548,7.375 9.563,7.565 9.563,7.755 C9.563,8.766 9.141,9.726 8.398,10.411 L8.398,12.037 L8.172,11.914 C7.521,11.559 6.783,11.372 6.038,11.372 L6.008,11.372 C3.540,11.372 1.533,13.379 1.533,15.847 L1.533,16.000 L-0.000,16.000 L-0.000,15.847 C-0.000,14.639 0.359,13.473 1.037,12.476 C1.653,11.571 2.494,10.856 3.479,10.397 C2.746,9.713 2.329,8.763 2.329,7.755 C2.329,5.760 3.952,4.138 5.946,4.138 C6.104,4.138 6.262,4.148 6.419,4.169 C6.390,3.984 6.375,3.796 6.375,3.609 C6.375,1.619 7.994,-0.000 9.984,-0.000 C11.975,-0.000 13.594,1.619 13.594,3.609 C13.594,4.603 13.187,5.543 12.470,6.224 C13.410,6.642 14.223,7.295 14.833,8.124 L15.012,8.368 L13.000,8.368 L12.957,8.329 ZM5.946,5.670 C4.797,5.670 3.862,6.605 3.862,7.755 C3.862,8.904 4.797,9.839 5.946,9.839 C7.096,9.839 8.031,8.904 8.031,7.755 C8.031,6.605 7.096,5.670 5.946,5.670 ZM9.984,1.533 C8.839,1.533 7.908,2.464 7.908,3.609 C7.908,4.754 8.839,5.686 9.984,5.686 C11.129,5.686 12.061,4.754 12.061,3.609 C12.061,2.464 11.129,1.533 9.984,1.533 Z"
    />
  </svg>
);

export default AddUsers;
