import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { AuthenticationMethod } from "./utils";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordConfirmForm from "./ResetPasswordConfirmForm";
import InvitationForm from "./InvitationForm";
import "./Authentication.css";

/**
 * This is the authentication page, it will render a Form
 * depending on the `method` url param. Defaults to login form.
 */
const Authentication: React.FC = () => {
  const history = useHistory();
  const { method } = useParams<{ method?: string }>();

  const handleLogin = (): void => {
    history.push("/");
  };

  let activeForm: JSX.Element | null;

  switch (method as AuthenticationMethod) {
    case AuthenticationMethod.Reset:
      activeForm = <ResetPasswordForm />;
      break;
    case AuthenticationMethod.ResetConfirm:
      activeForm = <ResetPasswordConfirmForm />;
      break;
    case AuthenticationMethod.Invitation:
      activeForm = <InvitationForm />;
      break;
    case AuthenticationMethod.Login:
    default:
      activeForm = <LoginForm onSuccess={handleLogin} />;
  }

  return (
    <div className="auth">
      <div className="auth__header">
        <div className="header__questions">
          Have questions? <a href="https://ebs-integrator.com">Contact us</a>
        </div>
      </div>

      <div className="auth__container">
        <div className="container__form">{activeForm}</div>
      </div>
      <p className="copyright">
        Designed by{" "}
        <a className="copyright__link" href="https://ebs-integrator.com">
          EBSIntegrator
        </a>
      </p>
    </div>
  );
};

export default Authentication;
