import React from "react";
import { FormWizard } from "components/ui";
import InviteUserForm from "../InviteUserForm";
import PermissionsUserForm from "../PermissionsUserForm";

const steps = [
  {
    title: "Invite new member in organization",
    stepTitle: "Invite user",
    component: <InviteUserForm />
  },
  {
    title: "Set permission for our member",
    stepTitle: "Set permissions",
    component: <PermissionsUserForm />
  }
];

const UserInviteWizard: React.FC = () => {
  return <FormWizard steps={steps} />;
};

export default UserInviteWizard;
