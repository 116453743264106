interface Props {
  active?: string;
  getUrl: (domain: string) => string;
}

export const useDomain = (): Props => {
  const location = window.location;
  const host = location.host.split(".");

  return {
    active: host && host[0],
    getUrl: (domain: string): string =>
      `${location.protocol}//${domain}.${[...host]
        .splice(1, host.length)
        .join(".")}`
  };
};
