import React, { useReducer, useContext } from "react";
import { Form, Input, Row, Col, Button, Spin, Modal, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { Link } from "react-router-dom";
import { Lock } from "components/svg";
import { ServiceTypes, SessionContext } from "@ebs-platform/components";
import { Tokens } from "@ebs-platform/components/lib/components/Session/interfaces";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import {
  FieldsValidationError,
  formItemLayout,
  formTailLayout
} from "../utils";
import { ReducerState, reducer } from "./store";
import OrganizationLogo from "./OrganizationLogo";
import { removeSpaces } from "../../../utils/strings";

export interface LoginFormProps extends FormComponentProps {
  onSuccess: () => void;
}

const initialState: ReducerState = {
  loading: false,
  errorMsg: null
};

const LoginForm: React.FC<LoginFormProps> = ({ form, onSuccess }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetcher = useFetcher(ServiceTypes.SSO);
  const { setTokens } = useContext(SessionContext);

  const { getFieldDecorator } = form;

  const formSubmitHandler = (e: React.FormEvent): void => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, data) => {
      if (!err) {
        dispatch({ type: "SET_LOADING", errorMsg: null });

        try {
          const tokens = await fetcher<Tokens>("/authorization/token/", {
            data,
            request: { credentials: "include" }
          });

          if (tokens) {
            const error = await setTokens(tokens);

            if (error) {
              dispatch({ type: "SET_ERROR_MSG", errorMsg: error.toString() });
            } else {
              onSuccess();
            }
          }
        } catch (error) {
          if (error instanceof TypeError) {
            dispatch({ type: "REMOVE_LOADING", errorMsg: null });
            Modal.error({
              title: "Sign in failed, are you connected to the internet?"
            });
          } else if (error instanceof FieldsValidationError) {
            dispatch({ type: "REMOVE_LOADING", errorMsg: null });
            form.setFields(error.fields);
          } else {
            dispatch({ type: "SET_ERROR_MSG", errorMsg: error.messages });
          }
        }
      }
    });
  };

  return (
    <Form
      {...formItemLayout}
      onSubmit={formSubmitHandler}
      className="authentication-form"
    >
      <Spin spinning={state.loading} delay={300}>
        <Row>
          <Col {...formTailLayout}>
            <div className="authentication-form__title-wrapper">
              <OrganizationLogo
                message={(name): string => `Sign in into your ${name} account`}
              />
              {state.errorMsg && (
                <Alert type="error" message={state.errorMsg} />
              )}
            </div>
          </Col>
        </Row>

        <Form.Item label="Email">
          {getFieldDecorator("username", {
            rules: [
              { required: true, message: "E-mail is required." }
            ]
          })(<Input onChange={(ev): void => {
            ev.target.value = removeSpaces(ev.target.value);
          }} />)}
        </Form.Item>

        <Form.Item label="Password">
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Enter your password." }]
          })(<Input.Password onChange={(ev): void => {
            ev.target.value = removeSpaces(ev.target.value);
          }} />)}
        </Form.Item>

        <Row>
          <Col {...formTailLayout}>
            <div className="form-options">
              <Link to={"/authentication/reset-password"}>
                <div>
                  <Lock />
                  <span style={{ marginLeft: "8px" }}>Forgot password?</span>
                </div>
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          <Col {...formTailLayout}>
            <Button type="primary" htmlType="submit">
              SIGN IN
            </Button>
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default Form.create<LoginFormProps>({ name: "LoginForm" })(LoginForm);
