import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";
import { Team } from "libs/interfaces/users";
import { Profile } from "components/ui";
import { Users } from "components/svg";
import TeamForm from "components/UsersPage/TeamForm";
import { Edit, Trash } from "components/svg";
import TeamDescription from "./TeamDescription";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";

interface TeamProfileProps {
  team: Team | null;
  fetchTeam: (teamId: number) => void;
}

const TeamProfile: React.FC<TeamProfileProps> = ({ team, fetchTeam }) => {
  const history = useHistory();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const [visible, setVisibility] = useState(false);
  const [teamName, setTeamName] = useState<string>("");

  // Set team name
  useEffect(() => {
    if (team) {
      setTeamName(team.name);
    }
  }, [team]);

  if (team === null) {
    return (
      <div className="profile-wrapper">
        <Spin spinning />
      </div>
    );
  }

  // Delete team by id
  const deleteTeamById = async (): Promise<void> => {
    try {
      if (team.id) {
        await fetcher(`/organizations/team/${team.id}/`, {
          request: { method: "DELETE" }
        });
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  // Rename team by id
  const renameTeamById = async (name: string): Promise<void> => {
    if (team.id && name) {
      const data = { name };
      await fetcher(`/organizations/team/${team.id}/`, {
        data,
        request: { method: "PUT" }
      });

      // Fetch teams after rename
      fetchTeam(team.id);
      setTeamName(team.name);
      setVisibility(false);
    }
  };

  // Handle team profile
  const handleEdit = (): void => {
    setTeamName(team.name);
    setVisibility(true);
  };
  const handleDelete = (): void => {
    deleteTeamById().then(() => history.push("/users/"));
  };

  // Format date
  const formattedCreatedDate = team.date_created
    ? moment(team.date_created).format("DD/MM/YYYY HH:mm")
    : "";

  return (
    <>
      <Profile
        title={team.name}
        avatarIcon={<Users size={64} />}
        description={
          <TeamDescription
            dateCreated={formattedCreatedDate}
            usersCount={team.users.length}
          />
        }
      >
        <Actions onEdit={handleEdit} onDelete={handleDelete} />
      </Profile>
      <TeamForm
        key="rename-team-details"
        title="Rename team"
        visible={visible}
        defaultName={teamName}
        onOk={renameTeamById}
        onCancel={(): void => setVisibility(false)}
      />
    </>
  );
};

export default React.memo(TeamProfile);

interface ActionProps {
  onEdit: () => void;
  onDelete: () => void;
}
const Actions = withPermissions<ActionProps>([OrganizationUserRole.ADMIN])(
  (props) => (
    <>
      <Profile.Action text="Edit" svgIcon={<Edit />} onClick={props.onEdit} />
      <Profile.Action
        text="Delete"
        svgIcon={<Trash />}
        onClick={props.onDelete}
        className="btn-dark-background"
      />
    </>
  )
);
