import React from "react";
import { Avatar, Icon, Tooltip } from "antd";

export interface ProfileItemProps {
  email: string;
  username?: string;
  first_name?: string;
  last_name?: string;
}

interface ProfileItem {
  item: ProfileItemProps;
}

interface TooltipTitle {
  email: string;
}

const TooltipTitle: React.FC<TooltipTitle> = ({ email }) => {
  return (
    <>
      <Icon type="user" /> {email}
    </>
  );
};

const ProfileItem: React.FC<ProfileItem> = ({ item }) => {
  return (
    <Tooltip
      placement="bottomRight"
      title={<TooltipTitle email={item.email} />}
      overlayClassName="profile-item__tooltip"
    >
      <Avatar className="profile-item">
        {item.username
          ? item.username
          : `${item.first_name?.charAt(0) || ""}
          ${item.last_name?.charAt(0) || ""}`}
      </Avatar>
    </Tooltip>
  );
};

export default ProfileItem;
