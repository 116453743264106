import { Tokens } from "@ebs-platform/components";
import { ServiceConfig } from "@ebs-platform/components/esm";
import { get } from "js-cookie";

type CheckCookieResult = [Error | null, Tokens | null];

export const logout = async (
  config: ServiceConfig
): Promise<CheckCookieResult> => {
  const SaasAppToken = get("app_token");
  const url = `${config.baseUrl}/authorization/token/logout/`;
  let tokens: Tokens | null = null;
  let error: Error | null = null;

  try {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        /*eslint no-useless-computed-key:*/
        ["Saas-app-token"]: SaasAppToken as string
      }
    });
    const data = await res.json();

    if (res.ok) {
      tokens = data;
    } else {
      // error = new ResponseError(url, res.status, data, res);
    }
  } catch (e) {
    error = e as Error;
  }

  return [error, tokens];
};
