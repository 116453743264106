import React from "react";
import "./TableFooter.css";

interface TableFooter {
  count?: number;
  showNavigation?: boolean;
  previous?: string;
  next?: string;
}

const TableFooter: React.FC<TableFooter> = ({ count }) => {
  return (
    <div className="table-footer">
      {count && (
        <div className="table-footer__records">{`${count} records`}</div>
      )}
    </div>
  );
};

TableFooter.defaultProps = {
  count: 0
};

export default TableFooter;
