import React, { useEffect, useReducer, useState, useCallback } from "react";
import { message } from "antd";
import orderBy from "lodash/orderBy";
import { TeamData } from "libs/interfaces/users";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";
import { PageHeader, IconButton, SortButton } from "components/ui";
import { SortDirectionType } from "components/ui/SortButton";
import { AddUsers } from "components/svg";
import TeamsTable from "./TeamsTable";
import TeamForm from "./TeamForm";
import { initialState, reducer, ReducerActionType } from "./store";
import { WithResults } from "types";

// Sorting
const sortItems = [
  {
    key: "name",
    title: "By name"
  },
  {
    key: "date_created",
    title: "By date"
  }
];

const TeamsTableContainer: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const organization = useOrganization();
  const [filteredTeams, setFilteredTeams] = useState<TeamData[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirectionType>("asc");
  const [sortBy, setSortBy] = useState<string>(sortItems[0].key);

  // Modal visibility for add new team
  const [visible, setVisibility] = useState(false);

  // Fetch teams
  const fetchOrganizationTeams = useCallback(async () => {
    try {
      if (organization) {
        const teams = await fetcher<WithResults<TeamData>>(
          `/organizations/teams/${organization.id}/`
        );
        dispatch({
          type: ReducerActionType.FETCH_TEAMS,
          teams: teams.results || []
        });
        setFilteredTeams(teams.results || []);
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [organization, fetcher]);

  // Add new team
  const addTeam = async (
    teamName: string,
    usersEmails: string[]
  ): Promise<void> => {
    const data = {
      name: teamName,
      emails: usersEmails
    };

    if (organization) {
      await fetcher(`/organizations/teams/create/${organization.id}/`, {
        data
      });
    }

    // Fetch organization team after adding new one
    fetchOrganizationTeams();
  };

  useEffect(() => {
    fetchOrganizationTeams();
  }, [fetchOrganizationTeams]);

  // Handlers for adding new team modal
  const handleOk = async (
    teamName: string,
    usersEmails: string[] = []
  ): Promise<void> => {
    await addTeam(teamName, usersEmails);
    setVisibility(false);
  };

  const handleCancel = (): void => {
    setVisibility(false);
  };

  // Handle Search
  const handelChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;

    const teams = state.teams.filter((team: TeamData) =>
      team.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredTeams(teams);
  };

  // Handle Sorting
  const handleSortDirection = (direction: SortDirectionType): void => {
    setSortDirection(direction);
  };

  const handleSort = (sort: string): void => {
    setSortBy(sort);
  };

  useEffect(
    () => {
      const orderedArray = orderBy(filteredTeams, [sortBy], [sortDirection]);
      setFilteredTeams(orderedArray);
    },
    // filteredTeams cannot be set to deps, will trigger infinite update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortDirection, sortBy]
  );

  return (
    <>
      <PageHeader title="My Teams" onChangeSearch={handelChangeSearch}>
        <SortButton
          options={sortItems}
          onChange={handleSort}
          onChangeDirection={handleSortDirection}
        />
        <IconButton
          text="Add team"
          svgIcon={<AddUsers />}
          onClick={(): void => setVisibility(true)}
        />
      </PageHeader>
      <TeamsTable teams={filteredTeams} fetchTeams={fetchOrganizationTeams} />
      <TeamForm
        key="add-team"
        title="Add new team"
        visible={visible}
        hasAddingUsers={true}
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </>
  );
};

export default TeamsTableContainer;
