import React from "react";

const SortAscend: React.FC = () => (
  <svg width="16px" height="13px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M6.666,3.353 L6.666,2.020 L16.000,2.020 L16.000,3.353 L6.666,3.353 ZM3.333,2.963 L3.333,12.688 L2.000,12.688 L2.000,2.963 L0.943,4.020 L-0.000,3.077 L2.195,0.882 C2.455,0.621 2.877,0.621 3.138,0.882 L5.333,3.077 L4.390,4.019 L3.333,2.963 ZM14.000,6.020 L6.666,6.020 L6.666,4.687 L14.000,4.687 L14.000,6.020 ZM12.000,8.687 L6.666,8.687 L6.666,7.354 L12.000,7.354 L12.000,8.687 ZM10.000,11.354 L6.666,11.354 L6.666,10.021 L10.000,10.021 L10.000,11.354 Z"
    />
  </svg>
);

export default SortAscend;
