import React from "react";

const Add: React.FC = () => (
  <svg width="12px" height="12px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M-0.000,4.800 L4.800,4.800 L4.800,-0.000 L7.200,-0.000 L7.200,4.800 L12.000,4.800 L12.000,7.200 L7.200,7.200 L7.200,12.000 L4.800,12.000 L4.800,7.200 L-0.000,7.200 L-0.000,4.800 Z"
    />
  </svg>
);

export default Add;
