import React from "react";

const SearchUser: React.FC = () => (
  <svg width="24px" height="24px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M23.698,23.707 C23.503,23.902 23.247,24.000 22.991,24.000 C22.736,24.000 22.480,23.902 22.285,23.707 L18.029,19.450 C16.121,21.042 13.669,22.001 10.996,22.001 C4.933,22.001 -0.000,17.067 -0.000,11.001 C-0.000,4.935 4.933,-0.000 10.996,-0.000 C17.059,-0.000 21.992,4.935 21.992,11.001 C21.992,13.674 21.033,16.127 19.443,18.035 L23.698,22.293 C24.089,22.683 24.089,23.316 23.698,23.707 ZM19.992,11.001 C19.992,6.038 15.957,2.000 10.996,2.000 C6.035,2.000 1.999,6.038 1.999,11.001 C1.999,15.964 6.035,20.001 10.996,20.001 C15.957,20.001 19.992,15.964 19.992,11.001 ZM10.996,11.001 C13.572,11.001 15.661,13.090 15.661,15.668 L6.331,15.668 C6.331,13.090 8.419,11.001 10.996,11.001 ZM10.996,11.001 C9.339,11.001 7.997,9.657 7.997,8.000 C7.997,6.344 9.339,5.000 10.996,5.000 C12.652,5.000 13.995,6.344 13.995,8.000 C13.995,9.657 12.652,11.001 10.996,11.001 Z"
    />
  </svg>
);

export default SearchUser;
