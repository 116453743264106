import React from "react";

const Trash: React.FC = () => (
  <svg width="12px" height="14px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M1.852,12.513 C1.933,13.361 2.646,14.000 3.511,14.000 L8.488,14.000 C9.353,14.000 10.067,13.361 10.148,12.513 L10.943,4.183 L1.056,4.183 L1.852,12.513 ZM6.852,8.148 L6.790,9.926 L5.210,9.926 L5.148,8.148 L6.852,8.148 ZM5.109,7.055 L5.047,5.277 L6.953,5.277 L6.890,7.055 L5.109,7.055 ZM7.964,8.148 L9.449,8.148 L9.279,9.926 L7.902,9.926 L7.964,8.148 ZM6.751,11.019 L6.685,12.906 L5.314,12.906 L5.248,11.019 L6.751,11.019 ZM4.098,9.926 L2.721,9.926 L2.551,8.148 L4.036,8.148 L4.098,9.926 ZM2.958,12.411 L2.825,11.019 L4.136,11.019 L4.203,12.906 L3.511,12.906 C3.223,12.906 2.985,12.693 2.958,12.411 ZM8.488,12.906 L7.797,12.906 L7.863,11.019 L9.174,11.019 L9.041,12.411 C9.014,12.693 8.777,12.906 8.488,12.906 ZM9.553,7.055 L8.002,7.055 L8.064,5.277 L9.723,5.277 L9.553,7.055 ZM3.936,5.277 L3.998,7.055 L2.447,7.055 L2.277,5.277 L3.936,5.277 ZM8.639,1.994 L8.639,1.641 C8.639,0.736 7.891,-0.000 6.972,-0.000 L5.028,-0.000 C4.109,-0.000 3.361,0.736 3.361,1.641 L3.361,1.994 L-0.000,1.994 L-0.000,3.088 L12.000,3.088 L12.000,1.994 L8.639,1.994 ZM4.472,1.641 C4.472,1.339 4.721,1.094 5.028,1.094 L6.972,1.094 C7.278,1.094 7.528,1.339 7.528,1.641 L7.528,1.994 L4.472,1.994 L4.472,1.641 Z"
    />
  </svg>
);

export default Trash;
