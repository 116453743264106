import { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

const orderBy = "order_by";
const orderDirection = "order_direction";

interface SortState {
  sortBy?: string;
  sortDirection?: string;
}

interface ActiveOptions extends SortState {
  changeSort: (sort: string) => void;
  changeDirection: (sort: string) => void;
  params: URLSearchParams | null;
}

/**
 * `useActiveOptions` is dedicated for SortButton.
 *
 * URL location serves as state, and it's should while it's reactive.
 *
 * It handles `sortBy` and `sortDirection` options, both: extract and modify
 * by using `react-router-dom` hooks `useLocations` & `useHistory`.
 *
 * This hook returns:
 * - `changeSort` - change method for sortBy value
 * - `changeDirection` - change method for direction
 * - `params` - a memoized URLSearchParams value (used for requests)
 * - `sortBy` & `sortDirection` - raw params values (used for SortButton state)
 *
 * @param sortByKey string - default key value for sort by
 * @param sortDirectionKey string - default key value for sort direction
 */
const useActiveOptions = (
  sortByKey = orderBy,
  sortDirectionKey = orderDirection
): ActiveOptions => {
  const history = useHistory();
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const getParamValues = useMemo((): SortState => {
    const sortBy = params.get(sortByKey) || undefined;
    const sortDirection = params.get(sortDirectionKey) || undefined;

    return { sortBy, sortDirection };
  }, [sortByKey, sortDirectionKey, params]);

  const [{ sortBy, sortDirection }, setSort] = useState<SortState>(
    getParamValues
  );

  useEffect(() => {
    setSort(getParamValues);
  }, [getParamValues]);

  const changeSort = useCallback(
    (sort: string) => {
      if (params.get(sortByKey) === sort) {
        params.delete(sortByKey);
      } else {
        params.set(sortByKey, sort);
      }

      history.push(`?${params}`);
    },
    [history, params, sortByKey]
  );

  const changeDirection = useCallback(
    (direction: string) => {
      params.set(sortDirectionKey, direction);
      history.push(`?${params}`);
    },
    [history, params, sortDirectionKey]
  );

  const requestParams = useMemo(() => {
    const params = new URLSearchParams();

    if (sortBy) {
      params.set(sortByKey, sortBy);
    }

    if (sortDirection) {
      params.set(sortDirectionKey, sortDirection);
    }

    return Array.from(params).length ? params : null;
  }, [sortBy, sortDirection, sortByKey, sortDirectionKey]);

  return {
    sortBy,
    sortDirection,
    changeSort,
    changeDirection,
    params: requestParams
  };
};

export default useActiveOptions;
