// tslint:disable max-line-length
import React from "react";

const Lock: React.FC = () => (
  <svg width="16px" height="18px">
    <path
      fill="rgb(0, 82, 204)"
      d="M14.218,18.000 L1.782,18.000 C0.799,18.000 -0.000,17.247 -0.000,16.321 L-0.000,8.268 C-0.000,7.342 0.799,6.589 1.782,6.589 L3.109,6.589 L3.109,3.875 C3.109,1.738 4.954,-0.000 7.223,-0.000 L8.777,-0.000 C11.046,-0.000 12.891,1.738 12.891,3.875 L12.891,6.589 L14.218,6.589 C15.201,6.589 16.000,7.342 16.000,8.268 L16.000,16.321 C16.000,17.247 15.201,18.000 14.218,18.000 ZM10.881,3.875 C10.881,2.782 9.937,1.893 8.777,1.893 L7.223,1.893 C6.063,1.893 5.119,2.782 5.119,3.875 L5.119,6.589 L10.881,6.589 L10.881,3.875 ZM13.990,8.482 L2.010,8.482 L2.010,16.107 L13.990,16.107 L13.990,8.482 ZM8.000,9.518 C8.983,9.518 9.782,10.271 9.782,11.196 C9.782,11.747 9.487,12.264 9.005,12.576 L9.005,15.072 L6.995,15.072 L6.995,12.576 C6.512,12.264 6.218,11.747 6.218,11.196 C6.218,10.271 7.017,9.518 8.000,9.518 Z"
    />
  </svg>
);

export default Lock;
