import React from "react";

const MenuIcons: Record<string, React.ReactNode> = {
  Dashboard: (
    <svg viewBox="0 0 18 18" width="1em" height="1em">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M9,0L0,9l1.1,1.1l1.2-1.2V18h13.5V8.9l1.2,1.2L18,9L9,0z M10.5,16.5h-3V12h3V16.5z M14.2,16.5H12v-6H6v6H3.8 V7.4L9,2.1l5.2,5.2V16.5z M7.9,5.6h2.2v2.2H7.9V5.6z"
      />
    </svg>
  ),
  Applications: (
    <svg viewBox="0 0 18 18" width="1em" height="1em">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M18,1.1H9.7V0H8.3v1.1H0v13.7h5l-1.4,2.6L4.9,18l1.7-3.2h1.7V18h1.4v-3.2h1.7l1.7,3.2l1.2-0.7L13,14.8h5V1.1 L18,1.1z M16.6,13.4H1.4V2.5h15.2V13.4L16.6,13.4z M8.3,3.5H2.8v3.9h5.5L8.3,3.5L8.3,3.5z M6.9,6H4.2V4.9h2.7L6.9,6L6.9,6z M15.2,3.5H9.7v3.9h5.5L15.2,3.5L15.2,3.5z M13.8,6h-2.7V4.9h2.7L13.8,6L13.8,6z M8.3,8.4H2.8v3.9h5.5L8.3,8.4L8.3,8.4z M6.9,10.9 H4.2V9.8h2.7L6.9,10.9L6.9,10.9z M15.2,8.4H9.7v3.9h5.5L15.2,8.4L15.2,8.4z M13.8,10.9h-2.7V9.8h2.7L13.8,10.9L13.8,10.9z"
      />
    </svg>
  ),
  People: (
    <svg viewBox="0 0 18 18" width="1em" height="1em">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M18,17.1c-0.1-1-0.4-2.1-0.8-3c-1-2.1-2.8-3.8-5-4.6c2.3-1.7,2.8-5,1.1-7.4c-1.7-2.3-5-2.8-7.4-1.1 s-2.8,5-1.1,7.4c0.3,0.4,0.7,0.8,1.1,1.1c-3.3,1.2-5.6,4.2-5.8,7.7c0,0.2,0.1,0.4,0.2,0.6C0.4,17.9,0.6,18,0.8,18l0,0 c0.4,0,0.8-0.3,0.8-0.7c0.3-4.1,3.9-7.1,8-6.8c3.6,0.3,6.4,3.2,6.8,6.8c0,0.4,0.4,0.7,0.8,0.7l0,0c0.2,0,0.4-0.1,0.6-0.3 C17.9,17.6,18,17.4,18,17.1z M11.6,2.7c0.7,0.7,1.1,1.7,1.1,2.7c-0.1,2-1.8,3.6-3.8,3.5C8,8.9,7.1,8.5,6.4,7.8 C5.7,7.1,5.3,6.2,5.4,5.1C5.4,3.2,7,1.7,8.9,1.6c0,0,0.1,0,0.1,0C10,1.6,10.9,2,11.6,2.7L11.6,2.7z"
      />
    </svg>
  ),
  Chat: (
    <svg viewBox="0 0 18 18" width="1em" height="1em">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M18.333,18.666 L29.333,24.000 L24.000,18.666 L21.333,18.666 L21.333,24.000 C21.333,25.466 20.133,26.666 18.667,26.666 L8.000,26.666 L2.667,32.000 L2.667,26.666 C1.200,26.666 -0.000,25.466 -0.000,24.000 L-0.000,10.667 C-0.000,9.200 1.200,8.000 2.667,8.000 L10.667,8.000 L10.667,2.667 C10.667,1.200 11.867,0.000 13.333,0.000 L29.333,0.000 C30.799,0.000 32.000,1.200 32.000,2.667 L32.000,16.000 C32.000,17.466 30.799,18.666 29.333,18.666 ZM10.667,16.000 L5.333,16.000 L5.333,13.334 L10.667,13.334 L10.667,10.667 L2.667,10.667 L2.667,24.000 L18.667,24.000 L18.667,18.666 L13.333,18.666 C11.867,18.666 10.667,17.466 10.667,16.000 ZM29.333,2.667 L13.333,2.667 L13.333,16.000 L29.333,16.000 L29.333,2.667 ZM26.667,8.000 L16.000,8.000 L16.000,5.333 L26.667,5.333 L26.667,8.000 ZM26.667,13.334 L16.000,13.334 L16.000,10.667 L26.667,10.667 L26.667,13.334 ZM5.333,18.666 L9.333,18.666 L9.333,21.334 L5.333,21.334 L5.333,18.666 Z"
      ></path>
    </svg>
  )
};

export default MenuIcons;
