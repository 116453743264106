import React from "react";
import { Menu, Dropdown } from "antd";
import { ClickParam } from "antd/es/menu";
import { SortAscend, SortDescend } from "components/svg";
import { SortDirectionType } from "./index";
import useActiveOptions from "./useActiveOptions";
import "./SortButton.css";

interface MenuItem {
  key: string;
  title: string;
}

const renderDropdown = (
  items: MenuItem[],
  sortBy: string,
  onClick: (event: ClickParam) => void
): React.ReactNode => {
  return (
    <Menu
      onClick={onClick}
      className="sort-button__dropdown"
      selectedKeys={[sortBy]}
    >
      <Menu.Item className="sort-button__dropdown__item sort-button__dropdown__header">
        SORT BY
      </Menu.Item>

      {items &&
        items.map((item: MenuItem) => {
          return (
            <Menu.Item key={item.key} className="sort-button__dropdown__item">
              {item.title}
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

interface SortButtonProps {
  options?: MenuItem[];
  onChange?: (sortBy: string) => void;
  onChangeDirection?: (sortDirection: SortDirectionType) => void;
}

const SortButton: React.FC<SortButtonProps> = ({
  options = [],
  onChange,
  onChangeDirection
}) => {
  const {
    sortBy = options.length ? options[0].key : "",
    sortDirection = "asc",
    changeSort,
    changeDirection
  } = useActiveOptions();

  const handleMenuClick = (event: ClickParam): void => {
    changeSort(event.key);

    if (onChange) {
      onChange(event.key);
    }
  };

  const handleSortDirection = (): void => {
    const direction: SortDirectionType =
      sortDirection === "asc" ? "desc" : "asc";

    changeDirection(direction);

    if (onChangeDirection) {
      onChangeDirection(direction);
    }
  };

  const sortDropdown = options
    ? renderDropdown(options, sortBy, handleMenuClick)
    : null;

  const sortTitle =
    options && options.find((option: MenuItem) => option.key === sortBy);

  return (
    <div className="sort-button">
      <span className="sort-button__icon" onClick={handleSortDirection}>
        {sortDirection === "asc" ? <SortAscend /> : <SortDescend />}
      </span>
      <Dropdown overlay={sortDropdown} trigger={["click"]}>
        <span className="sort-button__title">
          {sortTitle && sortTitle.title}
        </span>
      </Dropdown>
    </div>
  );
};

SortButton.defaultProps = {
  options: [
    {
      key: "first_name",
      title: "By name"
    }
  ]
};

export default SortButton;
