import React from "react";
import UserProfileForm from "./UserProfileForm";

/**
 * This is "page" component for compay profile module.
 *
 * The page is mounted at `/company`, see routes.
 */
const UserProfilePage: React.FC = () => <UserProfileForm />;

export default UserProfilePage;
