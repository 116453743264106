import React, { useState } from "react";
import { Steps } from "antd";
import { PageTitle } from "@ebs-platform/common";
import FormWizardContext, { FormData } from "./context";
import "./FormWizard.css";

const { Step } = Steps;

interface FormWizardSteps {
  stepTitle: string;
  component: React.ReactNode;
  title?: string; // is used for page title
}

interface FormWizardProps {
  steps: FormWizardSteps[];
  currentStep?: number;
}

/**
 * FormWizard is used to create a step-by-step form
 * @param currentStep - current step where to start, by default is 0
 * @param steps - array of steps with title and the step component
 */
const FormWizard: React.FC<FormWizardProps> = ({ currentStep = 0, steps }) => {
  const [step, setStep] = useState(currentStep);
  const [formData, setFormData] = useState<FormData | null>(null);

  const component = steps[step].component;
  const title = steps[step].title;

  // Handle next step and save the data from the step
  // to use it  for another step
  const handleNextStep = (data?: FormData): void => {
    if (steps.length !== step + 1) {
      setStep(step + 1);
    }

    if (data) {
      setFormData(data);
    }
  };

  const handlePrevStep = (): void => {
    if (step !== 0) {
      setStep(step - 1);
    }
  };

  const renderSteps = (): React.ReactNode => {
    return (
      <Steps size="small" current={step} className="invite-steps">
        {steps.map(({ stepTitle }) => (
          <Step key={stepTitle} title={stepTitle} className="invite-step" />
        ))}
      </Steps>
    );
  };

  return (
    <FormWizardContext.Provider
      value={{
        data: formData,
        currentStep: step,
        nextStep: handleNextStep,
        prevStep: handlePrevStep
      }}
    >
      {title && (
        <div className="form-wizard__header">
          <PageTitle title={title} />
        </div>
      )}
      {renderSteps()}
      {component}
    </FormWizardContext.Provider>
  );
};

export default FormWizard;
