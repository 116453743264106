import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { ActionOption, ServiceTypes } from "@ebs-platform/components";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { TableFooter, TableColumnActions } from "components/ui/Table";

import { User, normalizeUsers, UserProps } from "../utils";
import { paginationCustomButtons, renderTeams } from "./utils";
import TableUserProfile from "../TableUserProfile";
import ActionsColumn from "../../ActionsColumn";
import { UsersData } from "libs/interfaces/users";
import UserRole from "../UserRole";

interface UsersTableProps {
  usersData: UsersData | null;
  fetchUsers: () => void;
  onChangePagination?: (page: number) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  usersData,
  fetchUsers,
  onChangePagination
}) => {
  const history = useHistory();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const params: { pageNumber?: string } = useParams<{ pageNumber?: string }>();
  const paramsPageNumber: number = parseFloat(params?.pageNumber || "") || 1;
  const [visible, setVisibility] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const handleIsActiveUser = async (user: User): Promise<void> => {
    if (!user.user.id) return;

    try {
      await fetcher(`/organizations/user/${user.user.sso_id}/edit/`, {
        request: {
          method: "PATCH",
          body: JSON.stringify({ is_active: !user.user.is_active })
        }
      });

      message.success(
        `User was successfully ${
          user.user.is_active ? "disabled" : "activated"
        }`
      );
      fetchUsers();
    } catch (e) {
      const error = e as Error;
      message.error(error.message);
    }
  };

  // Handle change user role
  const handleModalOk = (): void => {
    // Fetch users after user role was changed
    fetchUsers();
    setVisibility(false);
  };

  const handleEdit = (user: User): void => {
    setVisibility(true);

    if (user) {
      setCurrentUser(user);
    }
  };

  const handlePermissions = (user: User): void => {
    if (user.user.id) {
      history.push(`/users/permissions/${user.user.id}`);
    }
  };

  const data = usersData !== null ? normalizeUsers(usersData.users) : [];

  // Pagination
  const handlePaginationChange = (page: number): void => {
    history.push(`/people/users/${page}`);

    if (onChangePagination) {
      onChangePagination(page);
    }
  };

  useEffect(() => {
    if (onChangePagination && paramsPageNumber) {
      onChangePagination(paramsPageNumber);
    }
  }, [paramsPageNumber, onChangePagination]);

  // Pagination config
  const paginationConfig = usersData !== null && {
    current: paramsPageNumber,
    total: usersData.count,
    pageSize: 20,
    className: "table-pagination",
    onChange: handlePaginationChange,
    itemRender: paginationCustomButtons
  };

  const columnActionOptions = (item: User): ActionOption[] => [
    {
      name: "Edit",
      onClick: handleEdit
    },
    {
      name: item.user.is_active ? "Disable" : "Activate",
      onClick: handleIsActiveUser
    },
    {
      name: "Permissions",
      onClick: handlePermissions
    }
  ];

  return (
    <>
      <Table
        className="table"
        dataSource={data}
        pagination={paginationConfig}
        footer={(items: User[]): React.ReactNode => (
          <TableFooter count={items.length} />
        )}
      >
        <Table.Column
          title="User"
          dataIndex="user"
          key="user"
          render={(text: UserProps): React.ReactNode => (
            <TableUserProfile user={text} />
          )}
        />
        <Table.Column
          title="Last Activity"
          dataIndex="last_login"
          key="last_login"
        />
        <Table.Column title="Function" dataIndex="job_title" key="job_title" />
        <Table.Column title="Role" dataIndex="role" key="role" />
        <Table.Column
          title="Teams"
          dataIndex="teams"
          key="teams"
          render={renderTeams}
        />
        <Table.Column
          title="Activated"
          dataIndex="user.is_active"
          key="is_activate"
          render={(value: boolean): string => (value ? "Yes" : "No")}
        />

        <ActionsColumn
          className="table-cell-actions"
          key="actions"
          render={(action, item: User): React.ReactNode => (
            <TableColumnActions
              item={item}
              options={columnActionOptions(item)}
            />
          )}
        />
      </Table>
      <UserRole
        visible={visible}
        user={currentUser}
        onOk={handleModalOk}
        onCancel={(): void => setVisibility(false)}
      />
    </>
  );
};

export default UsersTable;
