import React from "react";

const Dots: React.FC = () => (
  <svg width="16px" height="4px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M14.133,4.000 C13.104,4.000 12.266,3.102 12.266,2.000 C12.266,0.897 13.104,-0.000 14.133,-0.000 C15.162,-0.000 16.000,0.897 16.000,2.000 C16.000,3.102 15.162,4.000 14.133,4.000 ZM8.000,4.000 C6.971,4.000 6.133,3.102 6.133,2.000 C6.133,0.897 6.971,-0.000 8.000,-0.000 C9.029,-0.000 9.866,0.897 9.866,2.000 C9.866,3.102 9.029,4.000 8.000,4.000 ZM1.866,4.000 C0.837,4.000 -0.000,3.102 -0.000,2.000 C-0.000,0.897 0.837,-0.000 1.866,-0.000 C2.895,-0.000 3.733,0.897 3.733,2.000 C3.733,3.102 2.895,4.000 1.866,4.000 Z"
    />
  </svg>
);

export default Dots;
