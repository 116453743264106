import React, { useEffect, useState } from "react";
import { Modal, Input, message } from "antd";
import * as queryString from "query-string";
import { LabeledValue } from "antd/es/select";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";
import { ValidationError } from "@ebs-platform/components/esm/errors";
import { UsersData } from "libs/interfaces/users";
import UserSelect, { OptionItem } from "../UserForm/UserSelect";
import { normalizeUserOption } from "../UserForm/utils";
import UserSelectOption from "../UserForm/UserSelect/UserSelectOption";
import {inviteUrl} from "utils/strings";
import "./TeamForm.css";

interface TeamFormProps {
  title: string;
  visible: boolean;
  defaultName?: string;
  hasAddingUsers?: boolean;
  hasInputName?: boolean;
  onOk: (teamName: string, usersEmails?: string[]) => Promise<void>;
  onCancel: () => void;
  className?: string;
}

const TeamForm: React.FC<TeamFormProps> = ({
  title,
  visible,
  hasAddingUsers,
  hasInputName,
  defaultName,
  onOk,
  onCancel,
  className
}) => {
  const organization = useOrganization();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  // New team or rename team
  const [teamName, setTeamName] = useState<string>("");

  // Adding users to team
  const [selectedValues, setValues] = useState<LabeledValue[]>([]);
  const [selectOptions, setSelectOptions] = useState<OptionItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [usersEmails, setUsersEmails] = useState<string[]>([]);

  // Get users' emails from selected users
  const getUsersEmails = (options: LabeledValue[]): void => {
    const emails = options.map((option: LabeledValue) => {
      const { props } = option.label as React.ReactElement;

      return props.option.description;
    });

    setUsersEmails(emails);
  };

  // Invite user to organization
  const inviteUserToOrganization = async (): Promise<void> => {
    let invitedUsers = null;

    try {
      if (organization) {
        const data = {
          organization_id: organization.id,
          emails: [searchValue],
          invite_url: inviteUrl
        };

        invitedUsers = await fetcher(`/users/invite/`, { data });
      }

      if (invitedUsers) {
        const options: OptionItem[] = normalizeUserOption(invitedUsers);

        const returnedOptions = options.map((option: OptionItem) => {
          return {
            key: `${option.id}`,
            label: <UserSelectOption option={option} />
          };
        });

        const values = [...selectedValues, ...returnedOptions];

        setValues(values);
        getUsersEmails(values);
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    if (defaultName) {
      setTeamName(defaultName);
    }

    if (searchValue && organization) {
      (async (): Promise<void> => {
        const query = queryString.stringify({ search: searchValue });
        const searchedUsers = await fetcher<UsersData>(
          `/organizations/users/${organization.id}/?${query}`
        );
        if (searchedUsers) {
          const options: OptionItem[] = normalizeUserOption(
            searchedUsers.users
          );
          setSelectOptions(options);
        }
      })();
    }
  }, [defaultName, searchValue, organization, fetcher]);

  const handleModalOk = async (): Promise<void> => {
    try {
      if (hasAddingUsers) {
        await onOk(teamName, usersEmails);
      } else {
        await onOk(teamName);
      }

      setTeamName("");
    } catch (e) {
      if (e instanceof ValidationError) {
        const { fields, messages } = e;

        if (messages.length) {
          return message.error(messages);
        } else if (fields) {
          // TODO: Extract form item error by using form
          return message.error(e.toString());
        }
      }

      return message.error(e.toString());
    }
  };

  // Handle team name
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTeamName(e.currentTarget.value);
  };

  // Handle user select change
  const handleChange = (selectedValue: LabeledValue[]): void => {
    setValues(selectedValue);
    getUsersEmails(selectedValue);
  };

  const handleSearch = (term: string): void => {
    setSearchValue(term);
  };

  const handleInviteClick = (): void => {
    inviteUserToOrganization();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleModalOk}
      onCancel={onCancel}
      className={className}
      destroyOnClose={true}
      afterClose={(): void => {
        setUsersEmails([]);
        setValues([]);
      }}
    >
      <div className="team-form">
        {hasInputName && (
          <div className="team-form__row">
            <label>Name: </label>
            <Input
              className="team-form__input"
              placeholder="Enter team name..."
              value={teamName}
              defaultValue={defaultName}
              onChange={handleInputChange}
              size="large"
            />
          </div>
        )}

        {hasAddingUsers && (
          <div className="team-form__row">
            <label>Users: </label>
            <UserSelect
              className="team-form__select"
              options={selectOptions}
              selectedOptions={selectedValues}
              searchedTerm={searchValue}
              onChange={handleChange}
              onSearch={handleSearch}
              onInviteClick={handleInviteClick}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

TeamForm.defaultProps = {
  visible: false,
  hasAddingUsers: false,
  hasInputName: true
};

export default TeamForm;
