import React from "react";

const AddUser: React.FC = () => (
  <svg width="14px" height="14px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M11.773,11.769 L11.773,14.000 L10.314,14.000 L10.314,11.769 L8.087,11.769 L8.087,10.307 L10.314,10.307 L10.314,8.075 L11.773,8.075 L11.773,10.307 L14.000,10.307 L14.000,11.769 L11.773,11.769 ZM6.987,8.262 C3.939,8.262 1.459,10.747 1.459,13.800 L1.459,14.000 L-0.000,14.000 L-0.000,13.800 C-0.000,11.931 0.727,10.173 2.046,8.851 C2.717,8.178 3.494,7.661 4.358,7.311 C3.416,6.529 2.863,5.365 2.863,4.131 C2.863,1.853 4.713,-0.000 6.987,-0.000 C9.260,-0.000 11.110,1.853 11.110,4.131 C11.110,6.409 9.260,8.262 6.987,8.262 ZM6.987,1.462 C5.518,1.462 4.322,2.659 4.322,4.131 C4.322,5.603 5.518,6.800 6.987,6.800 C8.456,6.800 9.651,5.603 9.651,4.131 C9.651,2.659 8.456,1.462 6.987,1.462 Z"
    />
  </svg>
);

export default AddUser;
