// Form Layout
export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
    md: { span: 3 },
    lg: { span: 2 },
    xl: { span: 2 },
    xxl: { span: 2 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 21 },
    md: { span: 12 },
    lg: { span: 12 },
    xl: { span: 12 },
    xxl: { span: 10 }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasErrors(fieldsError: any): boolean {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}
