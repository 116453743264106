import React, { useEffect, useReducer, useState, useCallback } from "react";
import { message, Table } from "antd";
import { useParams } from "react-router-dom";
import { SortButton } from "components/ui";
import * as queryString from "query-string";
import { OrganizationUser, Team, UsersData } from "libs/interfaces/users";
import { SortDirectionType } from "components/ui/SortButton";
import {
  TableFooter,
  TableHeader,
  TableColumnActions
} from "components/ui/Table";
import { User, normalizeUsers, UserProps } from "../utils";
import { initialState, reducer, ReducerActionType } from "../store";
import UserRole from "../UserRole";
import TeamForm from "../TeamForm";
import TeamProfile from "./TeamProfile";
import { renderUserLink, sortItems } from "./utils";
import AddUserButton from "./AddUserButton";
import "./TeamDetails.css";
import ActionsColumn from "components/ActionsColumn";
import TableUserProfile from "../TableUserProfile";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";

const TeamDetails: React.FC = () => {
  const params: { teamId?: string } = useParams<{ teamId?: string }>();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const organization = useOrganization();
  const [state, dispatch] = useReducer(reducer, initialState);

  // Change user role visibility
  const [visible, setVisibility] = useState(false);

  // Add user to team visibility
  const [addUserVisible, setAddUserVisibility] = useState(false);

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [users, setUsers] = useState<OrganizationUser[]>([]);

  // Sort, filter and search
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirectionType>("asc");
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch team
  const fetchTeamById = useCallback(
    async (teamId: number) => {
      try {
        if (teamId) {
          const fetchedTeam = await fetcher<Team>(
            `/organizations/team/${teamId}/`
          );

          dispatch({ type: ReducerActionType.FETCH_TEAM, team: fetchedTeam });
          setUsers(fetchedTeam.users);
        }
      } catch (e) {
        message.error(e.message);
      }
    },
    [fetcher]
  );

  // Delete user by id
  const deleteTeamUser = async (userId: number): Promise<void> => {
    try {
      const teamId = Number(params?.teamId);
      await fetcher(`/organizations/user/team/${teamId}/${userId}/`, {
        request: { method: "DELETE" }
      });

      // Fetch team after deleting user
      fetchTeamById(teamId);
    } catch (e) {
      message.error(e.message);
    }
  };

  // Add user to team
  const addTeamUser = async (usersEmails: string[]): Promise<void> => {
    const team_id = Number(params?.teamId);
    const data = {
      emails: usersEmails,
      team_id
    };

    await fetcher(`/organizations/user/team/`, { data });

    // Fetch team after adding user
    fetchTeamById(team_id);
  };

  useEffect(() => {
    fetchTeamById(Number(params?.teamId));
  }, [fetchTeamById, params]);

  // Handle change user role
  const handleModalOk = (): void => {
    // Fetch team after user role was changed
    fetchTeamById(Number(params?.teamId));
    setVisibility(false);
  };

  // Handlers for adding users to team
  const handleAddUserClick = (): void => {
    setAddUserVisibility(true);
  };
  const handleAddUserOk = async (
    _teamName: string,
    usersEmails: string[] = []
  ): Promise<void> => {
    await addTeamUser(usersEmails);
    setAddUserVisibility(false);
  };
  const handleAddUserCancel = (): void => {
    setAddUserVisibility(false);
  };

  // Handle users search
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.currentTarget.value);
  };

  const handleSort = (sortKey: string): void => {
    setSortBy(sortKey);
  };

  const handleSortDirection = (direction: SortDirectionType): void => {
    setSortDirection(direction);
  };

  // Fetch organization users
  const fetchOrganizationUsersTeam = useCallback(async () => {
    try {
      const requestParams = {
        search: searchTerm,
        order_by: sortBy,
        order_direction: sortDirection,
        team_id: params?.teamId
      };

      if (organization) {
        const query = queryString.stringify(requestParams);
        const usersData = await fetcher<UsersData>(
          `/organizations/users/${organization.id}/?${query}`
        );

        if (usersData !== null) {
          setUsers(usersData.users);
        }
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [searchTerm, sortBy, sortDirection, params, organization, fetcher]);

  useEffect(() => {
    fetchOrganizationUsersTeam();
  }, [fetchOrganizationUsersTeam]);

  const usersActionOptions = [
    {
      name: "Edit",
      onClick: (user: User): void => {
        setVisibility(true);

        if (user) {
          setCurrentUser(user);
        }
      }
    },
    {
      name: "Delete",
      onClick: (item: User): void => {
        if (item.user.id) {
          deleteTeamUser(item.user.id);
        }
      }
    }
  ];

  const data = normalizeUsers(users);

  return (
    <div className="team-details__wrapper">
      <TeamProfile team={state.team} fetchTeam={fetchTeamById} />
      <Table
        className="table table-small"
        dataSource={data}
        title={(items: User[]): React.ReactNode => (
          <TableHeader
            title="Users"
            count={items.length}
            onChangeSearch={handleSearch}
          >
            <SortButton
              options={sortItems}
              onChange={handleSort}
              onChangeDirection={handleSortDirection}
            />
            <AddUserButton onClick={handleAddUserClick} />
          </TableHeader>
        )}
        footer={(items: User[]): React.ReactNode => (
          <TableFooter count={items.length} />
        )}
      >
        <Table.Column
          title="User"
          dataIndex="user"
          key="user"
          render={(user: UserProps): React.ReactNode => (
            <TableUserProfile user={user} />
          )}
        />
        <Table.Column
          title="Last Activity"
          dataIndex="last_login"
          key="last_login"
        />
        <Table.Column title="Function" dataIndex="job_title" key="job_title" />
        <Table.Column title="Role" key="role" dataIndex="role" />
        <Table.Column
          title="Teams"
          dataIndex="teams"
          key="teams"
          render={renderUserLink}
        />

        <ActionsColumn
          className="table-cell-actions"
          key="actions"
          render={(action, item): React.ReactNode => (
            <TableColumnActions item={item} options={usersActionOptions} />
          )}
        />
      </Table>

      <UserRole
        visible={visible}
        user={currentUser}
        onOk={handleModalOk}
        onCancel={(): void => setVisibility(false)}
      />

      <TeamForm
        key="add-user-team"
        title="Add users to team"
        visible={addUserVisible}
        hasAddingUsers={true}
        hasInputName={false}
        onOk={handleAddUserOk}
        onCancel={handleAddUserCancel}
      />
    </div>
  );
};

export default TeamDetails;
