// Create acronym from user's first name and last name
export const getNameAcronym = (name: string | undefined): string | null => {
  const str = name || "";
  const matches = str.match(/\b(\w)/g);

  return matches && matches.join("");
};

export const isEmailValid = (
  email: string | undefined
): boolean | undefined => {
  // eslint-disable-next-line no-useless-escape
  const tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (email) {
    return tester.test(email);
  }
};

/**
 * getUrlSubDomain is used to parse the url to get the sub-domain
 */
export const getUrlSubDomain = (depth = 1): string => {
  return window.location.hostname
    .split(".")
    .slice(0, depth)
    .join(".");
};

export const removeSpaces = (str: string): string => {
  return String(str).replace(/ /g, '');
}

export const inviteUrl = `${window.location.protocol}//${window.location.hostname}/authentication/invitation/?token=`;