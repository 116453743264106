import { useContext, useMemo } from "react";

import { useAvailableApplications } from "@ebs-platform/components/esm/hooks";
import { ServiceTypes, SessionContext } from "@ebs-platform/components";

const thumbor_key = process.env.REACT_APP_THUMBOR_KEY;
const thumbor_server = process.env.REACT_APP_THUMBOR_SERVER;

/**
 * Creates configuration for CreateConnect from `@ebs-platform/connect`
 * @returns undefined if env variables `REACT_APP_THUMBOR_KEY` and `REACT_APP_THUMBOR_SERVER` are missing
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useChatConfig = () => {
  const applications = useAvailableApplications();
  const { session, apiConfig } = useContext(SessionContext);
  const configApiConnect = useMemo(() => apiConfig && apiConfig[ServiceTypes.CONNECT], [apiConfig]);
  const config = useMemo(
    () => ({
      token: session?.tokens.access ? session.tokens.access : "default",
      saasAppToken: applications.chat?.token,
      secretKey: thumbor_key || "ebsio",
      API_URL: configApiConnect?.baseUrl || "default",
      API_FILE_SERVICE: thumbor_server || "default",
      isFullScreen: false
    }),
    [session, applications, configApiConnect]
  );
  if (!thumbor_key || !thumbor_server) return undefined;
  return config;
};

export default useChatConfig;
