import React from "react";
import { IconButton } from "components/ui";
import { AddUser } from "components/svg";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";

interface AddUserButtonProps {
  onClick: () => void;
}

const AddUserButton: React.FC<AddUserButtonProps> = ({ onClick }) => (
  <IconButton
    text="Add user"
    svgIcon={<AddUser />}
    onClick={onClick}
  />
)

export default withPermissions<AddUserButtonProps>([OrganizationUserRole.ADMIN])(AddUserButton);