import React from "react";
import { RouteProps } from "react-router-dom";
import { NotFound } from "@ebs-platform/common";
import ApplicationPage from "components/ApplicationPage";
// import DashboardPage from "components/DashboardPage";
import UsersPage from "components/UsersPage";
import UserDetails from "components/UsersPage/UserDetails";
import TeamDetails from "components/UsersPage/TeamDetails";
import UserInviteWizard from "components/UsersPage/UserInviteWizard";
import UserAddWizard from "components/UsersPage/UserAddWizard";
import PermissionsUserForm from "components/UsersPage/PermissionsUserForm";
import Authentication from "components/Authentication";
import { UserProfilePage } from "components/UserProfile";

interface AppRoute extends Omit<RouteProps, "path"> {
  name: string;
  path: string | string[];
  component: React.ComponentType;
  isPublic?: boolean;
  exact?: boolean;
}

const routes: AppRoute[] = [
  {
    name: "Authentication",
    path: "/authentication/:method?",
    component: Authentication,
    isPublic: true
  },
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  //   component: DashboardPage,
  //   exact: true
  // },
  {
    name: "Applications",
    path: ["/", "/applications"],
    component: ApplicationPage,
    exact: true
  },
  {
    name: "People",
    path: "/people/:tab(users/:pageNumber|teams)?",
    component: UsersPage
  },
  {
    name: "Add user",
    path: "/users/add",
    component: UserAddWizard
  },
  {
    name: "Invite user and set permissions",
    path: "/users/invite",
    component: UserInviteWizard
  },
  {
    name: "Permissions",
    path: "/users/permissions/:userId",
    component: PermissionsUserForm
  },
  {
    name: "Team details",
    path: "/teams/:teamId",
    component: TeamDetails
  },
  {
    name: "User details",
    path: "/users/:userId",
    component: UserDetails
  },
  {
    name: "User profile",
    path: "/profile",
    component: UserProfilePage
  },
  // Internal not found, is used in layout
  {
    name: "Not Found",
    path: "*",
    component: NotFound
  },
  // External not found, has its own layout
  {
    name: "Not Found",
    path: "/not-found",
    component: NotFound,
    isPublic: true
  }
];

export default routes;
