import React, { useEffect, useReducer } from "react";
import { message } from "antd";
import { SubscribedApplication } from "libs/interfaces/applications";
import { ServiceTypes } from "@ebs-platform/components";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { useActiveOptions } from "components/ui/SortButton";
import ApplicationModal from "./ApplicationModal";
import ApplicationHeader from "./ApplicationHeader";
import ApplicationList from "./ApplicationList";
import ApplicationContext from "./context";
import { initialState, reducer, ReducerActionType } from "./store";
import "./ApplicationPage.css";

const ApplicationPage: React.FC = () => {
  const { params } = useActiveOptions();
  const organization = useOrganization();
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  useEffect(() => {
    /**
     * This will fetch applications for the specified organization.
     */
    const fetchSubscribedApps = async (): Promise<void> => {
      try {
        if (organization) {
          const subscribedApplications = await fetcher<SubscribedApplication[]>(
            `/applications/${organization.id}/?${params ?? ""}`
          );

          dispatch({
            type: ReducerActionType.SET_SUBSCRIBED_APPLICATIONS,
            subscribedApplications
          });
        }
      } catch (e) {
        message.error(e.message);
      }
    };

    fetchSubscribedApps();
  }, [params, organization, fetcher]);

  return (
    <div className="app-page">
      <ApplicationContext.Provider
        value={{
          subscribedApplications: state.subscribedApplications,
          addSubscribedApplication: (
            subscribedApplication: SubscribedApplication | null
          ): void =>
            dispatch({
              type: ReducerActionType.ADD_SUBSCRIBED_APPLICATION,
              subscribedApplication
            }),
          deleteSubscribedApplication: (token: string): void => {
            dispatch({
              type: ReducerActionType.SET_SUBSCRIBED_APPLICATIONS,
              subscribedApplications: state.subscribedApplications.filter(
                (item) => item.token !== token
              )
            });
          },
          toggleModal: (): void =>
            dispatch({ type: ReducerActionType.TOOGLE_MODAL })
        }}
      >
        <ApplicationModal visible={state.visible} />

        <ApplicationHeader />

        <ApplicationList />
      </ApplicationContext.Provider>
    </div>
  );
};

export default ApplicationPage;
