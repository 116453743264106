import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDebounce } from "react-use";
import { PageHeader, SortButton } from "components/ui";
import AddAppButton from "./AddAppButton";
import { useSearchParam, useURLParams } from "hooks";
import ApplicationContext from "../context";

import "./ApplicationHeader.scss";

const sortOptions = [{ key: "name", title: "Name" }];

const ApplicationHeader: React.FC = () => {
  const [value, setValue] = useState("");

  const params = useURLParams();
  const history = useHistory();
  const defaultValue = useSearchParam("name");
  const { subscribedApplications, toggleModal } = useContext(
    ApplicationContext
  );

  useDebounce(
    () => {
      if (value) {
        params.set("name", value);
      } else {
        params.delete("name");
      }

      history.push(`?${params}`);
    },
    250,
    [value]
  );

  const handleSearch = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(value);
  };

  return (
    <PageHeader
      defaultValue={defaultValue}
      onChangeSearch={handleSearch}
      title={`My Applications (${subscribedApplications.length})`}
      className="app-header"
    >
      <SortButton options={sortOptions} />
      <AddAppButton onClick={toggleModal} />
    </PageHeader>
  );
};

export default ApplicationHeader;
