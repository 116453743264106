import React from "react";

const Mail: React.FC = () => (
  <svg width="16px" height="12px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M14.031,-0.000 L1.969,-0.000 C0.883,-0.000 -0.000,0.853 -0.000,1.901 L-0.000,10.099 C-0.000,11.147 0.883,12.000 1.969,12.000 L14.031,12.000 C15.117,12.000 16.000,11.147 16.000,10.099 L16.000,1.901 C16.000,0.853 15.117,-0.000 14.031,-0.000 ZM5.203,5.965 L1.477,9.337 L1.477,2.650 L5.203,5.965 ZM6.292,6.935 L8.000,8.455 L9.709,6.934 L13.730,10.574 L2.270,10.574 L6.292,6.935 ZM8.000,6.515 L2.282,1.426 L13.718,1.426 L8.000,6.515 ZM10.798,5.965 L14.523,2.650 L14.523,9.337 L10.798,5.965 Z"
    />
  </svg>
);

export default Mail;
