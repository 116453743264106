import React, { useState, useContext, useEffect, useCallback } from "react";
import { Modal, Spin, Row, message } from "antd";
import {
  Application,
  SubscribedApplication
} from "libs/interfaces/applications";
import Actions from "components/Actions";
import ApplicationItem from "./ApplicationItem";
import ApplicationContext from "./context";
import Button from "antd/es/button";
import { ServiceTypes } from "@ebs-platform/components";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";

const ShortLink = React.lazy(() => import("./ApplicationForms/ShortLink"));

export enum ApplicationsTypes {
  SHORT_LINK = 8
}

interface FormProps {
  id: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.FC<any>;
}

const forms: { [key: number]: FormProps } = {
  [ApplicationsTypes.SHORT_LINK]: {
    id: "ShortLink",
    Component: ShortLink,
    title: "Add Short Link"
  }
};

interface ApplicationModalProps {
  visible: boolean;
}

interface FormStateProps {
  id?: string;
  title?: string;
  Component?: React.FC;
  visible: boolean;
}

const ApplicationModal: React.FC<ApplicationModalProps> = ({ visible }) => {
  const [form, setForm] = useState<FormStateProps>({ visible: false });
  const organization = useOrganization();
  const initialApplications: Application[] = [];
  const [applications, setApplications] = useState(initialApplications);
  const [loading, setLoading] = useState(true);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  const { addSubscribedApplication, toggleModal } = useContext(
    ApplicationContext
  );

  // Subscribe to Application
  const handleSubscribe = async (applicationId: number): Promise<void> => {
    if (forms[applicationId]) {
      setForm({ ...forms[applicationId], visible: true });
    } else {
      try {
        if (organization) {
          const data = {
            instance_type_id: applicationId,
            organization_id: organization.id
          };

          const subscribedApplication = await fetcher<SubscribedApplication>(
            "/applications/",
            { data }
          );
          addSubscribedApplication(subscribedApplication);
        }

        toggleModal();
      } catch (e) {
        message.error(e.message);
      }
    }
  };

  // Fetch applications for modal on click new application button
  const fetchRepositoryApps = useCallback(async (): Promise<void> => {
    try {
      if (organization) {
        const fetchedApplications = await fetcher<Application[]>(
          `/applications/repository/${organization.id}/`
        );
        setApplications(fetchedApplications);
      }

      setLoading(false);
    } catch (e) {
      message.error(e.message);
    }
  }, [organization, fetcher]);

  useEffect(() => {
    if (visible) {
      fetchRepositoryApps();
    }
  }, [fetchRepositoryApps, visible]);

  const onCancel = (): void => {
    toggleModal();
  };

  const onSave = (): void => {
    toggleModal();
  };

  const closeFormModal = useCallback((): void => {
    setForm({ visible: false });
  }, []);

  const { Component } = form;

  return (
    <Modal
      title="Application repository"
      width="85vw"
      visible={visible}
      onCancel={onCancel}
      className="app-header__modal"
      footer={<Actions onSave={onSave} onCancel={onCancel} />}
      destroyOnClose
      afterClose={closeFormModal}
    >
      <Spin spinning={loading} delay={300}>
        <Row gutter={{ xs: 8, sm: 20 }} type="flex" className="applications">
          {applications.map((app: Application) => {
            return (
              <ApplicationItem
                key={app.id}
                name={app.name}
                item={app}
                onSubscribe={handleSubscribe}
              />
            );
          })}
        </Row>
      </Spin>

      <Modal
        title={form.title}
        visible={form.visible}
        className="app-header__form-modal"
        onCancel={closeFormModal}
        destroyOnClose
        footer={
          <>
            <Button onClick={closeFormModal}>Cancel</Button>

            <Button htmlType="submit" type="primary" form={form.id}>
              Add
            </Button>
          </>
        }
      >
        {Component && <Component />}
      </Modal>
    </Modal>
  );
};

export default ApplicationModal;
