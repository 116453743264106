import React, { useEffect, useState } from "react";
import { Alert } from "antd";
import { ServiceTypes } from "@ebs-platform/components";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { getUrlSubDomain } from "utils/strings";
import styles from "./OrganizationLogo.module.css";

/**
 * This is public metadata about an organization
 */
interface OrganizationMeta {
  id: number;
  name: string;
  domain_name: string;
  date_created: string;
  photo: string;
}

enum Status {
  PENDING,
  RESOLVED,
  REJECTED
}

interface OrganizationLogoProps {
  message: (name?: string) => string;
}

interface OrganizationLogoState {
  status: Status;
  meta: OrganizationMeta | null;
  error: Error | null;
}

const initialState: OrganizationLogoState = {
  status: Status.PENDING,
  meta: null,
  error: null
};

/**
 * This will render organization logo and
 * a message with organization name when data will be available.
 *
 * During loading it will display placeholder and on error
 * it will render an `Alert`.
 *
 * @example
 * <OrganizationLogo />
 */
const OrganizationLogo: React.FC<OrganizationLogoProps> = ({ message }) => {
  const [state, setState] = useState<OrganizationLogoState>(initialState);
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  useEffect(() => {
    const fetchOrganizationMeta = async (): Promise<void> => {
      let status: Status = Status.RESOLVED;
      let meta: OrganizationMeta | null = null;
      let error: Error | null = null;

      try {
        const path = `/organizations/info/${getUrlSubDomain()}/`;
        meta = await fetcher<OrganizationMeta>(path);
      } catch (e) {
        status = Status.REJECTED;
        error = e;
      }

      setState({ meta, status, error });
    };

    fetchOrganizationMeta();
  }, [fetcher]);

  if (state.status === Status.REJECTED && state.error !== null) {
    return <Alert type="error" message={state.error.message} />;
  }

  if (state.status === Status.PENDING) {
    return (
      <div>
        <div className={`${styles.logo} ${styles.loading}`} />
        <div className={`${styles.msg} ${styles.loading}`} />
      </div>
    );
  }

  const msg = message(state.meta?.name);

  return (
    <div>
      <div className={styles.logo}>
        <img src={state.meta?.photo} alt="Organization logo" />
      </div>
      <div className={styles.msg}>{msg}</div>
    </div>
  );
};

export default React.memo(OrganizationLogo);
