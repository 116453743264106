import React from "react";
import { Avatar, List } from "antd";
import { OptionItem } from "./index";

interface SelectOption {
  option: OptionItem;
}

const UserSelectOption: React.FC<SelectOption> = ({ option }) => {
  return (
    <List.Item>
      <List.Item.Meta
        className={option.className}
        avatar={
          <Avatar src={option.photo} size={28}>
            {option.noPhotoTitle}
          </Avatar>
        }
        title={option.title}
        description={option.description}
      />
    </List.Item>
  );
};

export default UserSelectOption;
