import React, { useContext } from "react";
import { OrganizationUserRole } from "@ebs-platform/common";
import { SessionContext } from "@ebs-platform/components";

/**
 * This is the type of the builder function that
 * returns the react component that makes role checks.
 */
type WithPermissionType<T> = (Component: React.ComponentType<T>) => React.FC<T>;

/**
 * This function return a HOC builder function that build
 * a React component. This component subscribes to organization
 * context and checks if session user has necessary roles to use
 * the given Component.
 *
 * @param roles an array of allowed roles that can access the passed component.
 */
function withPermissions<T>(
  roles: OrganizationUserRole[]
): WithPermissionType<T> {
  return function (Component): React.FC<T> {
    const WithPermissions: React.FC<T> = (props) => {
      const { session } = useContext(SessionContext);

      const userRole = session?.data?.role as OrganizationUserRole;

      if (session !== null && roles.includes(userRole)) {
        return <Component {...props} />;
      }

      return null;
    };

    WithPermissions.displayName = `WithPermissions(${Component.displayName})`;

    return WithPermissions;
  };
}

export default withPermissions;
