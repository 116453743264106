import React from "react";
import { Link } from "react-router-dom";
import { AddUser } from "components/svg";
import { IconButton } from "components/ui";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";

const AddUserButton: React.FC = () => (
  <Link to="/users/add">
    <IconButton text="Add new user" svgIcon={<AddUser />} />
  </Link>
);

export default withPermissions([OrganizationUserRole.ADMIN])(AddUserButton);
