import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, message } from "antd";
import { LabeledValue } from "antd/es/select";
import { User, OrganizationUser } from "libs/interfaces/users";
import { IconButton, PageTitle } from "components/ui";
import { AddUser, Mail } from "components/svg";
import { FormWizardContext } from "components/ui/FormWizard";
import { ServiceTypes } from "@ebs-platform/components";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { normalizeUserOption } from "./utils";
import UserSelect, { OptionItem } from "./UserSelect";
import UserSelectOption from "./UserSelect/UserSelectOption";
import {inviteUrl} from "utils/strings";
import "./UserForm.css";

const UserForm: React.FC = () => {
  const { nextStep } = useContext(FormWizardContext);
  const organization = useOrganization();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const [selectedValues, setValues] = useState<LabeledValue[]>([]);
  const [selectOptions, setSelectOptions] = useState<OptionItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [usersEmails, setUsersEmails] = useState<string[]>([]);

  // Get users' emails from selected users
  const getUsersEmails = (options: LabeledValue[]): void => {
    const emails = options.map((option: LabeledValue) => {
      const { props } = option.label as React.ReactElement;

      return props.option.description;
    });

    setUsersEmails(emails);
  };

  // Invite user to organization
  const inviteUserToOrganization = useCallback(async (): Promise<void> => {
    let invitedUsers = null;

    try {
      if (organization) {
        const data = {
          organization_id: organization.id,
          emails: [searchValue],
          invite_url: inviteUrl
        };

        invitedUsers = await fetcher(`/users/invite/`, { data });
      }

      if (invitedUsers) {
        const options: OptionItem[] = normalizeUserOption(invitedUsers);

        const returnedOptions = options.map((option: OptionItem) => {
          return {
            key: `${option.id}`,
            label: <UserSelectOption option={option} />
          };
        });

        const values = [...selectedValues, ...returnedOptions];

        setValues(values);
        getUsersEmails(values);
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [organization, searchValue, selectedValues, fetcher]);

  useEffect(() => {
    // Fetch all users
    const fetchAllUsers = async (): Promise<void> => {
      try {
        if (searchValue) {
          const searchedUsers = await fetcher<User[]>(
            `/users/search/?email=${searchValue}`
          );
          const options: OptionItem[] = normalizeUserOption(searchedUsers);

          setSelectOptions(options);
        }
      } catch (e) {
        message.error(e.message);
      }
    };

    fetchAllUsers();
  }, [searchValue, fetcher]);

  // User select handlers
  const handleChange = (selectedValue: LabeledValue[]): void => {
    setValues(selectedValue);
    getUsersEmails(selectedValue);
  };

  const handleSearch = (term: string): void => {
    setSearchValue(term);
  };

  const handleDropdownChange = (open: boolean): void => {
    if (!open) {
      setSearchValue("");
    }
  };

  const handleInviteClick = useCallback((): void => {
    inviteUserToOrganization();
  }, [inviteUserToOrganization]);

  // Handle add users
  const handleClick = useCallback(async () => {
    try {
      if (organization) {
        const data = { emails: usersEmails };
        const organizationUsers = await fetcher<OrganizationUser[]>(
          `/organizations/users/create/${organization.id}/`,
          { data }
        );

        // Get the id of users and go to the next step, to PermissionsPage
        if (organizationUsers) {
          const userIds = organizationUsers.map((user) => user.id);
          nextStep(userIds);
        }
        setValues([]);
        setSelectOptions([]);
      }
    } catch (e) {
      message.error(e.message);
    }
  }, [usersEmails, organization, nextStep, fetcher]);

  return (
    <div className="user-form">
      <div className="users-page__header">
        <PageTitle title="Add New Member" />
        <Link to={"/users/invite"}>
          <IconButton text="Invite new member" svgIcon={<Mail />} />
        </Link>
      </div>

      <div className="user-form__wrapper">
        <div className="user-form__headings">
          <h3>Search by username, full name or email address</h3>
          <p>
            You’ll only be able to find a GitHub user by their email address
          </p>
        </div>

        <UserSelect
          className="user-select"
          options={selectOptions}
          selectedOptions={selectedValues}
          searchedTerm={searchValue}
          onChange={handleChange}
          onSearch={handleSearch}
          onDropdownChange={handleDropdownChange}
          onInviteClick={handleInviteClick}
        />

        <Divider className="divider" />

        <div className="users-page__buttons">
          <Button>Cancel</Button>
          <IconButton
            text="Add members"
            svgIcon={<AddUser />}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default UserForm;
