import React, { useCallback, useContext } from "react";
import { Button, Divider, Form, Input, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useHistory } from "react-router-dom";
import {
  useFetcher,
  useOrganization
} from "@ebs-platform/components/esm/hooks";
import { ServiceTypes } from "@ebs-platform/components";
import { OrganizationUser } from "libs/interfaces/users";
import { IconButton } from "components/ui";
import { FormWizardContext } from "components/ui/FormWizard";
import { AddUser } from "components/svg";
import { hasErrors, formItemLayout } from "./utils";
import {inviteUrl} from "utils/strings";
import "./InviteUserForm.css";

const UserInvite: React.FC<FormComponentProps> = ({ form }) => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched
  } = form;
  const organization = useOrganization();
  const { nextStep } = useContext(FormWizardContext);
  const history = useHistory();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);

  const formSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();
      form.validateFields(async (err, values) => {
        if (!err && organization) {
          const data = {
            organization_id: organization.id,
            emails: [values.email],
            invite_url: inviteUrl
          };

          try {
            const organizationUsers = await fetcher<OrganizationUser[]>(
              `/users/invite/`,
              { data }
            );

            if (organizationUsers.length > 0) {
              const [user] = organizationUsers;
              nextStep([user.id]);
            } else {
              message.warning(`The email ${values.email} already was invited!`);
            }
          } catch (error) {
            message.error(error.message);
          }

          // Reset inputs values
          form.resetFields(["email"]);
        }
      });
    },
    [form, organization, nextStep, fetcher]
  );

  const handleCancel = (): void => {
    history.push(`/people/users/1`);
  };

  // Only show error after a field is touched.
  const emailError = isFieldTouched("email") && getFieldError("email");

  return (
    <div className="users-page__wrapper">
      <Form
        className="user-form__invite"
        layout="vertical"
        onSubmit={formSubmitHandler}
        labelAlign="left"
      >
        <Form.Item
          {...formItemLayout}
          label="E-mail"
          validateStatus={emailError ? "error" : ""}
          help={emailError || ""}
        >
          {getFieldDecorator("email", {
            rules: [
              {
                type: "email",
                message: "The input is not valid E-mail!"
              },
              {
                required: true,
                message: "Please input E-mail!"
              }
            ]
          })(<Input type="email" placeholder="Enter email..." />)}
        </Form.Item>
      </Form>

      <Divider className="divider" />

      <div className="users-page__buttons">
        <Button onClick={handleCancel}>Cancel</Button>
        <IconButton
          text="Invite and add in your organization"
          svgIcon={<AddUser />}
          onClick={formSubmitHandler}
          disabled={hasErrors(getFieldsError(["email"]))}
        />
      </div>
    </div>
  );
};

const InviteUserForm = Form.create({ name: "user_invite" })(UserInvite);

export default InviteUserForm;
