
/**
 * The reducer state.
 */
export interface ReducerState {
  loading: boolean;
  errorMsg: string | null;
}

export interface ReducerAction {
  type: "SET_LOADING" | "REMOVE_LOADING" | "SET_ERROR_MSG";
  errorMsg: string | null;
}

export const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case "REMOVE_LOADING":
      return { loading: false, errorMsg: null };
    case "SET_ERROR_MSG":
      return { loading: false, errorMsg: action.errorMsg };
    case "SET_LOADING":
      return { loading: true, errorMsg: null };
    default:
      throw new Error("Unexpected action type");
  }
};