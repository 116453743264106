import React, { useContext, useMemo } from "react";
import { Row, message } from "antd";
import { ServiceTypes } from "@ebs-platform/components";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { SubscribedApplication } from "libs/interfaces/applications";
import ApplicationItem, { ApplicationItemCreatable } from "../ApplicationItem";
import { useSearchParam } from "hooks";
import ApplicationContext from "../context";

const ApplicationList: React.FC = () => {
  const query = useSearchParam("name") || "";

  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const { subscribedApplications, deleteSubscribedApplication } = useContext(
    ApplicationContext
  );

  const handleDelete = async (token?: string): Promise<void> => {
    if (token) {
      try {
        const application = await fetcher<SubscribedApplication>(
          `/applications/remove/${token}/`,
          { request: { method: "DELETE" } }
        );

        if (application) {
          deleteSubscribedApplication(token);
        }
      } catch (e) {
        const { message: errorMsg = "Can't unsubscribe from application" } = e;
        message.error(errorMsg);
      }
    }
  };

  const applications = useMemo(
    () =>
      subscribedApplications.filter(
        ({ name, instance_type }) =>
          name.includes(query) || instance_type.name?.includes(query)
      ),
    [query, subscribedApplications]
  );

  return (
    <Row
      gutter={{ xs: 8, sm: 16, md: 24, lg: 30, xl: 36 }}
      type="flex"
      className="applications"
    >
      <ApplicationItemCreatable />

      {applications.map((app: SubscribedApplication) => {
        return (
          <ApplicationItem
            key={app.id}
            name={app.name}
            item={app.instance_type}
            settings={app.settings}
            token={app.token}
            onDelete={handleDelete}
          />
        );
      })}
    </Row>
  );
};

export default ApplicationList;
