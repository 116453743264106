import React, { useState } from "react";
import { message, Table } from "antd";
import { TableFooter, TableColumnActions } from "components/ui/Table";
import TeamForm from "../TeamForm";
import { Team, normalizeTeams, NormalizedTeam } from "../utils";
import ActionsColumn from "components/ActionsColumn";
import { renderTeamLink, renderUserLink } from "./utils";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { TeamData } from "libs/interfaces/users";
import { ServiceTypes } from "@ebs-platform/components";

interface TeamsTableProps {
  teams: TeamData[];
  fetchTeams: () => void;
}

const TeamsTable: React.FC<TeamsTableProps> = ({ teams, fetchTeams }) => {
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const [visible, setVisibility] = useState(false);
  const [currentTeam, setCurrentTeam] = useState<Team | null>(null);

  // Delete team by id
  const deleteTeamById = async (teamId: number): Promise<void> => {
    try {
      await fetcher(`/organizations/team/${teamId}/`, {
        request: { method: "DELETE" }
      });

      // Fetch teams after deleting
      fetchTeams();
    } catch (e) {
      message.error(e.message);
    }
  };

  // Rename team by id
  const renameTeamById = async (name: string): Promise<void> => {
    if (currentTeam && currentTeam.id && name) {
      const data = { name };

      await fetcher(`/organizations/team/${currentTeam.id}/`, {
        data,
        request: { method: "PUT" }
      });

      // Fetch teams after rename
      fetchTeams();
      setVisibility(false);
    }
  };

  // Users columns actions
  const columnActionOptions = [
    {
      name: "Rename",
      onClick: (team: Team): void => {
        if (team) {
          setCurrentTeam(team);
          setVisibility(true);
        }
      }
    },
    {
      name: "Delete",
      onClick: (team: Team): void => {
        if (team.id) {
          deleteTeamById(team.id);
        }
      }
    }
  ];

  const data = normalizeTeams(teams);

  // Set default team name from current team
  const defaultTeamName = currentTeam ? currentTeam.name : "";

  return (
    <>
      <Table
        className="table"
        dataSource={data}
        footer={(items: NormalizedTeam[]): React.ReactNode => (
          <TableFooter count={items.length} showNavigation={false} />
        )}
      >
        <Table.Column
          title="Team"
          dataIndex="name"
          key="name"
          render={renderTeamLink}
        />

        <Table.Column
          title="Created on"
          dataIndex="date_created"
          key="date_created"
        />

        <Table.Column
          title="Users"
          dataIndex="users"
          key="users"
          render={renderUserLink}
        />

        <ActionsColumn
          className="table-cell-actions"
          key="actions"
          render={(action, item: NormalizedTeam): React.ReactNode => (
            <TableColumnActions item={item} options={columnActionOptions} />
          )}
        />
      </Table>
      <TeamForm
        key="rename-team"
        title="Rename team"
        visible={visible}
        defaultName={defaultTeamName}
        onOk={renameTeamById}
        onCancel={(): void => setVisibility(false)}
      />
    </>
  );
};

export default TeamsTable;
