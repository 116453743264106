import React, { useCallback, useReducer } from "react";
import { Form, Input, Row, Col, Modal, Alert, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useHistory } from "react-router-dom";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import {
  formItemLayout,
  formTailLayout,
  FieldsValidationError
} from "../utils";
import "./ResetPasswordForm.css";
import { ServiceTypes } from "@ebs-platform/components";
import { removeSpaces } from "../../../utils/strings";

interface ReducerState {
  loading: boolean;
  errorMsg: string | null;
}

interface ReducerAction {
  type: "SET_LOADING" | "REMOVE_LOADING" | "SET_ERROR_MSG";
  errorMsg: string | null;
}

const initialState: ReducerState = {
  loading: false,
  errorMsg: null
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case "REMOVE_LOADING":
      return { loading: false, errorMsg: null };
    case "SET_ERROR_MSG":
      return { loading: false, errorMsg: action.errorMsg };
    case "SET_LOADING":
      return { loading: true, errorMsg: null };
    default:
      throw new Error("Unexpected action type");
  }
};

const redirectUrl = `${window.location.origin}/authentication/reset-password-confirm?token=`;

const ResetPasswordForm: React.FC<FormComponentProps> = ({ form }) => {
  const history = useHistory();
  const fetcher = useFetcher(ServiceTypes.SSO);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getFieldDecorator } = form;

  getFieldDecorator("redirect_url", {
    initialValue: redirectUrl
  });

  const formSubmitHandler = useCallback(
    (e: React.FormEvent<Element>) => {
      e.preventDefault();

      form.validateFieldsAndScroll(async (err, data) => {
        if (!err) {
          try {
            const sucess = await fetcher<boolean>(
              `/authorization/user/restore/`,
              { data }
            );

            if (sucess) {
              Modal.success({
                title: `Restore password e-mail was successfully sent. Please, check your inbox for details.`,
                onOk: () => history.push("/authentication/login")
              });
            }
          } catch (error) {
            if (error instanceof TypeError) {
              dispatch({ type: "REMOVE_LOADING", errorMsg: null });
              Modal.error({
                title: "Sign in failed, are you connected to the internet?"
              });
            } else if (error instanceof FieldsValidationError) {
              dispatch({ type: "REMOVE_LOADING", errorMsg: null });
              form.setFields(error.fields);
            } else {
              dispatch({ type: "SET_ERROR_MSG", errorMsg: error.message });
            }
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher]
  );

  return (
    <Form
      {...formItemLayout}
      className="authentication-form reset-password-form"
      onSubmit={formSubmitHandler}
    >
      <Row>
        <Col {...formTailLayout}>
          <div className="authentication-form__title-wrapper">
            <div className="title-wrapper__title">Forgot password</div>

            <div className="title-wrapper__subtitle">
              Enter your e-mail and we will send you a new one.
            </div>
            {state.errorMsg && <Alert type="error" message={state.errorMsg} />}
          </div>
        </Col>
      </Row>

      <Form.Item label="E-mail">
        {getFieldDecorator("username", {
          rules: [
            {
              type: "email",
              message: "This is an invalid e-mail."
            },
            { required: true, message: "E-mail is required." }
          ]
        })(<Input onChange={(ev): void => {
          ev.target.value = removeSpaces(ev.target.value);
        }} />)}
      </Form.Item>
      <Row>
        <Col {...formTailLayout}>
          <Button type="primary" htmlType="submit" onClick={formSubmitHandler}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Form.create({ name: "ResetPasswordForm" })(ResetPasswordForm);
