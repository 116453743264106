import React from "react";
import { IconButton } from "components/ui";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";

interface Props {
  loading?: boolean;
  onSyncButton?: () => void;
}

const SyncUserButton: React.FC<Props> = ({ loading, onSyncButton }) => {
  return (
    <IconButton
      text="Sync AD"
      loading={loading}
      className="sync-ad-button"
      onClick={onSyncButton}
    />
  );
};

export default withPermissions<Props>([OrganizationUserRole.ADMIN])(
  SyncUserButton
);
