import React from "react";
import { Avatar, Button, List } from "antd";
import { SearchUser } from "components/svg";
import { isEmailValid } from "utils/strings";

interface OptionNotFoundProps {
  keyword?: string;
  onInvite?: () => void;
}

const OptionNotFound: React.FC<OptionNotFoundProps> = ({
  keyword,
  onInvite
}) => {
  const title = keyword ? `for the “${keyword}”` : "";
  const isValid = isEmailValid(keyword);

  return (
    <div className="select-not-found">
      <List.Item>
        <List.Item.Meta
          avatar={
            <Avatar size={52}>
              <SearchUser />
            </Avatar>
          }
          title={`No results found ${title}`}
          description="Would you like to add a new user"
        />
        <div className="select-not-found__content">
          <Button type="primary" onClick={onInvite} disabled={!isValid}>
            Send invite to this user
          </Button>
        </div>
      </List.Item>
    </div>
  );
};

export default OptionNotFound;
