export const getBaseUrl = (): string => {
  const path = window.location.pathname
    .split("/")
    .filter((i) => i !== "")
    .slice(0, 3)
    .join("/");

  return `/${path}`;
};

export const getTokenFromUrl = (): string => {
  return window.location.pathname
    .split("/")
    .filter((i) => i !== "")
    .slice(2, 3)
    .join();
};
