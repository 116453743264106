import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Tabs } from "antd";
import TeamsTableContainer from "./TeamsTableContainer";
import UsersTableContainer from "./UsersTableContainer";
import "./UsersPage.css";

const { TabPane } = Tabs;

const UsersPage: React.FC = () => {
  // TODO: Find a better way for tabs table pagination
  const { tab = "users/1" }: { tab?: string } = useParams<{ tab?: string }>();
  const history = useHistory();

  const onTabClick = (key: string): void => {
    // Set default users page
    const query = key === "users" ? `${key}/1` : key;
    history.push(`/people/${query}`);
  };

  return (
    <div className="users-page">
      <Tabs defaultActiveKey={tab} onTabClick={onTabClick}>
        <TabPane tab="People" key="users/1">
          <UsersTableContainer />
        </TabPane>
        <TabPane tab="Teams" key="teams">
          <TeamsTableContainer />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UsersPage;
