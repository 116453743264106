import { SubscribedApplication } from "libs/interfaces/applications";

export interface ApplicationsState {
  subscribedApplications: SubscribedApplication[];
  visible: boolean;
}

export enum ReducerActionType {
  SET_SUBSCRIBED_APPLICATIONS,
  ADD_SUBSCRIBED_APPLICATION,
  TOOGLE_MODAL
}

export interface ReducerAction {
  type: ReducerActionType;
  subscribedApplications?: SubscribedApplication[];
  subscribedApplication?: SubscribedApplication | null;
}

export const initialState: ApplicationsState = {
  visible: false,
  subscribedApplications: []
};

export const reducer = (
  state: ApplicationsState,
  action: ReducerAction
): ApplicationsState => {
  switch (action.type) {
    case ReducerActionType.SET_SUBSCRIBED_APPLICATIONS: {
      if (!action.subscribedApplications) {
        throw new Error("Subscribed applications array missing");
      }

      return {
        ...state,
        subscribedApplications: action.subscribedApplications
      };
    }

    case ReducerActionType.ADD_SUBSCRIBED_APPLICATION: {
      if (!action.subscribedApplication) {
        throw new Error("Missing subscribed application object");
      }

      return {
        ...state,
        subscribedApplications: [
          ...state.subscribedApplications,
          action.subscribedApplication
        ]
      };
    }

    case ReducerActionType.TOOGLE_MODAL: {
      return {
        ...state,
        visible: !state.visible
      };
    }

    default:
      throw new Error("Unexpected action type");
  }
};
