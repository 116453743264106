/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Table } from "antd";
import { ColumnProps } from "antd/es/table";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";

/**
 * This wrapper exists only to hide actions columns
 * from non admin users.
 *
 * Because antd Table checks children props for valid
 * `ColumnProps` object we cannot abstract column props
 * in this component. See https://github.com/ant-design/ant-design/blob/3.x-stable/components/table/util.tsx#L54
 */
export default withPermissions<ColumnProps<any>>([OrganizationUserRole.ADMIN])(
  (props) => (
    <>
      <Table.Column {...props} />
    </>
  )
);
