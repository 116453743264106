import React, { Suspense } from "react";
import { Spin } from "antd";
import { PrivateRoutes } from "libs/router";
import AppLayout from "../AppLayout";

/**
 * This is `Fallback` component which is displayed while
 * `PrivateRoutes` are lazy loaded or validated for access.
 */
const Fallback: React.FC = () => (
  <div className="app-fallback">
    <Spin tip="Loading..." />
  </div>
);

/**
 * This component makes protects private routes from unauthorized users.
 *
 * With an server request for cookie validation, SessionGuard will
 * verify user's session and store in SessionContext AccountAccess.
 *
 * If SessionContext proves to be a valid one, private routers are rendered
 * in the app's view, otherwise, it will redirect user to login page.
 */

const SessionGuard: React.FC = () => (
  <Suspense fallback={<Fallback />}>
    <AppLayout>
      <PrivateRoutes />
    </AppLayout>
  </Suspense>
);

export default SessionGuard;
