import React from "react";

interface CardTitleProps {
  title: string;
  icon?: React.ReactNode;
  filter?: React.ReactNode;
}

const CardTitle: React.FC<CardTitleProps> = ({ title, icon, filter }) => {
  return (
    <div className="card__title">
      {icon && <div className="card__title__icon">{icon}</div>}
      {title}
      {filter}
    </div>
  );
};

export default CardTitle;
