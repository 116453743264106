import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Switch, Icon, Select, Row, Col } from "antd";
import { applicationTypes } from "@ebs-platform/common";
import {
  SubscribedApplication,
  ApplicationAccess
} from "libs/interfaces/applications";
import "./PermissionItem.css";

const { Option } = Select;

interface PermissionItemProps {
  item: SubscribedApplication;
  onChange: (id: number, role: string | null, active: boolean) => void;
  access: ApplicationAccess[];
}

interface PermissionItemState {
  role: string | null;
  active: boolean;
}

const PermissionItem: React.FC<PermissionItemProps> = ({
  item,
  onChange,
  access
}) => {
  const [state, setState] = useState<PermissionItemState>({
    role: null,
    active: false
  });

  // Application icons
  const { type, roles } = item.instance_type;
  const { icon: AppIcon, className } = applicationTypes[type]
    ? applicationTypes[type]
    : applicationTypes.defaultApplication;

  const handleChange = (role: string): void => {
    setState(prevState => ({ ...prevState, role }));
    onChange(item.id, role, state.active);
  };

  const handleSwitchChange = (active: boolean): void => {
    setState(prevState => ({ ...prevState, active }));
    onChange(item.id, state.role, active);
  };

  useEffect(() => {
    const appAccess = access.find(app => app.application === item.id);
    const newState: { [key: string]: string | boolean | null } = {
      active: !!appAccess
    };

    if (appAccess && appAccess.role !== null) {
      newState.role = appAccess.role;
    }

    setState(s => ({ ...s, ...newState }));
  }, [access, item.id]);

  const hasRoles: boolean = Array.isArray(roles) && roles.length > 0;
  const roleSelectPlaceholder = hasRoles ? "Select role" : "No roles";
  const roleSelect = state.role ? state.role : undefined; // undefined is used to display placeholder

  return (
    <Row type="flex" align="middle" className="permission-item">
      <Col
        xs={24}
        sm={24}
        md={9}
        lg={10}
        xl={12}
        className={classNames(
          "permission-item__app",
          `application__item--${className}`
        )}
      >
        <span className="application__item__icon">
          <AppIcon />
        </span>
        <span className="permission-item__app-title">{item.name}</span>
      </Col>

      <Col xs={24} sm={6} md={5} lg={4} className="permission-item__status">
        <span className="permission-item__label">Active</span>
        <Switch
          className="permission-item__status-switch"
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          onChange={handleSwitchChange}
          checked={state.active}
        />
      </Col>

      <Col
        xs={24}
        sm={18}
        md={10}
        lg={10}
        xl={8}
        className="permission-item__role"
      >
        <span className="permission-item__label">Select Role</span>
        <Select
          className="permission-item__role-select"
          dropdownClassName="permission-item__role-dropdown"
          placeholder={roleSelectPlaceholder}
          onChange={handleChange}
          disabled={!hasRoles || !state.active}
          value={roleSelect}
        >
          {roles &&
            roles.map((role: string) => (
              <Option
                key={role}
                className="permission-item__role-option"
                value={role}
              >
                {role}
              </Option>
            ))}
        </Select>
      </Col>
    </Row>
  );
};

export default PermissionItem;
