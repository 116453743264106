import React from "react";
import { Select } from "antd";
import { LabeledValue } from "antd/es/select";
import classNames from "classnames";
import { Chevron, Close } from "components/svg";
import OptionNotFound from "./OptionNotFound";
import UserSelectOption from "./UserSelectOption";
import { OptionItem } from "./index";
import "./UserSelect.css";

const { Option } = Select;

interface UserSelectProps {
  className?: string;
  options?: OptionItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions?: any;
  searchedTerm?: string;
  onChange?: (values: LabeledValue[]) => void;
  onSearch?: (term: string) => void;
  onDropdownChange?: (open: boolean) => void;
  onInviteClick?: () => void;
}

const UserSelect: React.FC<UserSelectProps> = ({
  className,
  options,
  selectedOptions,
  searchedTerm,
  onChange,
  onSearch,
  onDropdownChange,
  onInviteClick
}) => {
  return (
    <Select
      className={classNames("user-select", className)}
      dropdownClassName="user-select__dropdown"
      mode="multiple"
      size="large"
      labelInValue
      value={selectedOptions}
      placeholder="Search by email or name"
      notFoundContent={
        <OptionNotFound keyword={searchedTerm} onInvite={onInviteClick} />
      }
      filterOption={false}
      onChange={onChange}
      onSearch={onSearch}
      menuItemSelectedIcon={<Chevron />}
      removeIcon={<Close />}
      onDropdownVisibleChange={onDropdownChange}
    >
      {options &&
        options.map((option: OptionItem) => (
          <Option key={option.id} className="user-select__option">
            <UserSelectOption option={option} />
          </Option>
        ))}
    </Select>
  );
};

export default UserSelect;
