import React from "react";
import { IconButton } from "components/ui";
import { User } from "components/svg";

interface TeamDescriptionProps {
  dateCreated: string;
  usersCount: number;
}

const TeamDescription: React.FC<TeamDescriptionProps> = ({
  dateCreated,
  usersCount
}) => {
  return (
    <>
      <div className="team-profile__description">{dateCreated}</div>
      <IconButton
        text={`${usersCount} users`}
        svgIcon={<User />}
        type="default"
        className="btn-team-profile"
      />
    </>
  );
};

export default TeamDescription;
