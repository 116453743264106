import React, { useCallback, MouseEvent } from "react";
import classNames from "classnames";
import { Col, Modal } from "antd";
import { applicationTypes, OrganizationUserRole } from "@ebs-platform/common";
import { Application } from "libs/interfaces/applications";
import { Add, Close } from "components/svg";
import withPermissions from "hocs/withPermissions";

import "./ApplicationItem.scss";

const { confirm } = Modal;

interface ApplicationItemProps {
  name: string;
  item: Application;
  onDelete?: (token: string) => void;
  onSubscribe?: (id: number) => void;
  settings?: Record<string, string>;
  className?: string;
  token?: string;
}

function showDeleteConfirm(
  e: MouseEvent,
  onDelete?: (token: string) => void,
  token?: string
): void {
  e.preventDefault();

  confirm({
    title: "Are you sure delete this application?",
    content: "Some descriptions",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    okButtonProps: {
      className: "btn",
    },
    cancelButtonProps: {
      className: "btn btn-default",
    },
    onOk() {
      if (onDelete && token) {
        onDelete(token);
      }
    }
  });
}

const getAppUrl = (type: string, settings: Record<string, string>): string => {
  const { url, token } = settings;
  if (type === "link") {
    return url;
  }
  return `${window.location.protocol}//${window.location.host}/apps/${type}/${token}/`;
};

const ApplicationItem: React.FC<ApplicationItemProps> = ({
  name,
  item,
  className,
  onSubscribe,
  settings,
  token,
  onDelete
}) => {
  const { icon: AppIcon, className: appClassName } = applicationTypes[item.type]
    ? applicationTypes[item.type]
    : applicationTypes.defaultApplication;

  // Handle on subscribe application
  const handleSubscribe = useCallback(() => {
    if (onSubscribe && item.id) {
      onSubscribe(item.id);
    }
  }, [onSubscribe, item.id]);

  const renderAppContent = (): React.ReactNode => (
    <>
      <div className="application__item__controls">
        {!Object.prototype.hasOwnProperty.call(item, "is_installed") ||
        item.is_installed ? (
          <UnsubscribeButton onClick={(e): void => showDeleteConfirm(e, onDelete, token)} />
        ) : (
          <span
            className="application__item__controls__icon"
            onClick={handleSubscribe}
          >
            <Add />
          </span>
        )}
      </div>
      <div className="application__item__icon">{<AppIcon />}</div>
      <h4 className="application__item__title">{name}</h4>
      <p className="application__item__description">{item.description}</p>
    </>
  );

  const itemClassName = classNames(
    "application__item",
    "application__large",
    `application__item--${appClassName}`,
    className
  );

  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
      {token ? (
        <a
          href={getAppUrl(item.type, { ...settings, token })}
          className={itemClassName}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderAppContent()}
        </a>
      ) : (
        <div className={itemClassName}>{renderAppContent()}</div>
      )}
    </Col>
  );
};

export default ApplicationItem;

const UnsubscribeButton = withPermissions<{ onClick: (e: MouseEvent) => void }>(
  [OrganizationUserRole.ADMIN]
)(({ onClick }) => (
  <span
    className="application__item__controls__icon application__item__control--remove"
    onClick={onClick}
  >
    <Close />
  </span>
));
