import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { User } from "../utils";

// Custom Next and Previous buttons for pagination
export const paginationCustomButtons = (
  _page: number,
  type: string,
  originalElement: React.ReactElement<HTMLElement>
): React.ReactNode => {
  if (type === "prev") {
    return <Button className="table-pagination__btn">Previous</Button>;
  }
  if (type === "next") {
    return <Button className="table-pagination__btn">Next</Button>;
  }

  return originalElement;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderTeams = (_text: any, user: User): React.ReactNode => {
  const restItems = user.teams.slice(1, user.teams.length).length;
  const firstItem = user.teams.length > 0 ? user.teams[0].name : null;

  return (
    <>
      {firstItem}
      {restItems > 1 && (
        <Link to={`/users/${user.user.id}`} style={{ marginLeft: "2px" }}>
          +{restItems}
        </Link>
      )}
    </>
  );
};
