import React from "react";
import { Link } from "react-router-dom";
import { List, Avatar, Badge } from "antd";
import { UserProps } from "../utils";
import { getNameAcronym } from "utils/strings";
import "./TableUserProfile.css";

interface TableUserProfileProps {
  user: UserProps;
}

const TableUserProfile: React.FC<TableUserProfileProps> = ({ user }) => {
  const acronym = getNameAcronym(user.name);
  const badgeStatus = user.status === "active" ? "success" : "default";

  return (
    <List.Item.Meta
      className="table-user-profile"
      avatar={
        <Badge dot offset={[-3, 30]} status={badgeStatus}>
          <Avatar src={user.photo} size={32}>
            {acronym}
          </Avatar>
        </Badge>
      }
      title={<Link to={`/users/${user.id}`}>{user.name}</Link>}
      description={user.email}
    />
  );
};

export default TableUserProfile;
