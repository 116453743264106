import React, { useEffect, useState } from "react";
import { message, Modal, Select } from "antd";
import { User } from "../utils";
import "./UserRole.css";
import { OrganizationUserRole } from "@ebs-platform/common";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { OrganizationUser } from "libs/interfaces/users";
import "./UserRole.css";
import { ServiceTypes } from "@ebs-platform/components";

const { Option } = Select;

interface UserRoleProps {
  visible: boolean;
  user: User | OrganizationUser | null;
  onOk: () => void;
  onCancel: () => void;
  className?: string;
}

const UserRole: React.FC<UserRoleProps> = ({
  visible,
  user,
  onOk,
  onCancel,
  className
}) => {
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    if (user && user.role) {
      setRole(user.role);
    }
  }, [user]);

  // Change user role
  const changeUserRole = async (): Promise<void> => {
    try {
      if (user && user.id && role) {
        const data = { role };
        await fetcher(`/organizations/user/${user.id}/`, {
          data,
          request: { method: "PUT" }
        });
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  const handleModalOk = (): void => {
    // Change user role and then call onOk function to fetch data
    changeUserRole().then(() => {
      onOk();
    });
  };

  const handleSelectChange = (value: string): void => {
    setRole(value);
  };

  return (
    <Modal
      title="Change user role"
      visible={visible}
      onOk={handleModalOk}
      onCancel={onCancel}
      className={className}
      destroyOnClose={true}
      width={300}
    >
      <div className="user-role">
        <label>User Role: </label>
        <Select defaultValue={role} onChange={handleSelectChange}>
          <Option value={OrganizationUserRole.ADMIN}>Administrator</Option>
          <Option value={OrganizationUserRole.EMPLOYEE}>Employee</Option>
        </Select>
      </div>
    </Modal>
  );
};

export default UserRole;
