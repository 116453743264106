import React from "react";
import { IconButton } from "components/ui";
import { Add } from "components/svg";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";

interface AddAppButtonProps {
  onClick: () => void;
}

const AddAppButton: React.FC<AddAppButtonProps> = ({ onClick }) => (
  <IconButton
    text="New Application"
    svgIcon={<Add />}
    onClick={onClick}
  />
);

export default withPermissions<AddAppButtonProps>([OrganizationUserRole.ADMIN])(AddAppButton);