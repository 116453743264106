import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Tag, Spin, message } from "antd";
import { useFetcher } from "@ebs-platform/components/esm/hooks";
import { OrganizationUser } from "libs/interfaces/users";
import { Profile } from "components/ui";
import UserRole from "../UserRole";
import withPermissions from "hocs/withPermissions";
import { OrganizationUserRole } from "@ebs-platform/common";
import { Edit, Trash } from "components/svg";
import { ServiceTypes } from "@ebs-platform/components";

interface UserProfileDescriptionProps {
  email: string;
  lastLogin: string;
}

const UserProfileDescription: React.FC<UserProfileDescriptionProps> = ({
  email,
  lastLogin
}) => {
  return (
    <>
      <div className="user-profile__description">{email}</div>
      {lastLogin && <Tag className="user-profile__tag">{lastLogin}</Tag>}
    </>
  );
};

interface UserProfileProps {
  user: OrganizationUser | null;
  fetchUser: (userId: number) => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ user, fetchUser }) => {
  const history = useHistory();
  const fetcher = useFetcher(ServiceTypes.ORGANIZATION);
  const [visible, setVisibility] = useState(false);

  if (user === null) {
    return (
      <div className="profile-wrapper">
        <Spin spinning />
      </div>
    );
  }

  // Delete user by id
  const deleteUserById = async (): Promise<void> => {
    try {
      if (user.id) {
        await fetcher(`/organizations/user/${user.id}/`, {
          request: { method: "DELETE" }
        });
      }
    } catch (e) {
      message.error(e.message);
    }
  };

  // Handle user profile
  const handleEdit = (): void => {
    setVisibility(true);
  };

  const handleDelete = (): void => {
    deleteUserById().then(() => history.push("/users/"));
  };

  // Handle change user role
  const handleModalOk = (): void => {
    // Fetch user by id after user role was changed
    if (user.id) {
      fetchUser(user.id);
    }
    setVisibility(false);
  };

  const userData = user.user;

  // Format date
  const formattedLastLoginDate = userData.last_login
    ? moment(userData.last_login).format("DD/MM/YYYY HH:mm")
    : "";

  return (
    <>
      <Profile
        title={`${userData.first_name} ${userData.last_name}`}
        description={
          <UserProfileDescription
            email={userData.email}
            lastLogin={formattedLastLoginDate}
          />
        }
      >
        <Actions onEdit={handleEdit} onDelete={handleDelete} />
      </Profile>
      <UserRole
        visible={visible}
        user={user}
        onOk={handleModalOk}
        onCancel={(): void => setVisibility(false)}
      />
    </>
  );
};

export default React.memo(UserProfile);

interface ActionProps {
  onEdit: () => void;
  onDelete: () => void;
}
const Actions = withPermissions<ActionProps>([OrganizationUserRole.ADMIN])(
  (props) => (
    <>
      <Profile.Action text="Edit" svgIcon={<Edit />} onClick={props.onEdit} />
      <Profile.Action
        text="Delete"
        svgIcon={<Trash />}
        onClick={props.onDelete}
        className="btn-dark-background"
      />
    </>
  )
);
