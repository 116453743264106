import "./AppLayout.css";
import React, { useMemo, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { Badge, Layout as AntdLayout, Icon } from "antd";
import {
  Sidebar,
  Layout,
  Header,
  ServiceTypes
} from "@ebs-platform/components";
import { MenuItem } from "@ebs-platform/components/lib/components/Sidebar/interfaces";
import { SessionContext } from "@ebs-platform/components/esm";
import * as svg from "@ebs-platform/components/esm/svg";
import { CreateConnect } from "@ebs-platform/connect";

import { useChatConfig, useDomain } from "hooks";
import { UserRole } from "types";
import { getBaseUrl } from "utils/url";
import { logout } from "utils/logout";

import MenuIcons from "./MenuIcons";
import { User } from "../svg";

const { Content } = AntdLayout;
const { Applications, People } = MenuIcons;

interface ExtendedMenuItem extends MenuItem {
  path: string | string[];
  exact?: boolean;
}

export const accessTokenKey = "__access_token__";
export const saasTokenKey = "__saas_token__";

const loginUrl = "/authentication/login";

const clearStorage = (): void => {
  window.localStorage.removeItem(accessTokenKey);
  window.localStorage.removeItem(saasTokenKey);
};

const AppLayout: React.FC = ({ children }) => {
  const history = useHistory();
  const { session, removeSession, apiConfig } =
    React.useContext(SessionContext);
  const location = useLocation();
  const { active, getUrl } = useDomain();
  const chatConfig = useChatConfig();

  const [count, setCount] = useState(0);
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false);
  const closeChatDrawer = (): void => setChatDrawerOpen(false);

  const menuItems = useMemo(
    (): ExtendedMenuItem[] => [
      // {
      //   key: "dashboard",
      //   path: "/dashboard",
      //   title: "Dashboard",
      //   onClick: (): void => history.push("/dashboard"),
      //   icon: Dashboard
      // },
      {
        key: "applications",
        path: ["/", "/applications"],
        exact: true,
        title: "Applications",
        onClick: (): void => history.push("/applications"),
        icon: Applications
      },

      ...(session?.data?.role === UserRole.admin
        ? [
            {
              key: "people",
              path: ["/people", "/users"],
              title: "People",
              onClick: (): void => history.push("/people/users/1"),
              icon: People
            }
          ]
        : [])
    ],
    [session, history]
  );

  const selectedKey = useMemo(() => {
    const activeItem = menuItems.find((m) =>
      matchPath(location.pathname, {
        path: m.path,
        exact: m.exact
      })
    );

    return activeItem?.key;
  }, [location.pathname, menuItems]);

  const onRedirect = React.useCallback(
    () =>
      active &&
      getBaseUrl() !== loginUrl &&
      window.open(`${getUrl(active)}${loginUrl}`, "_self"),
    [active, getUrl]
  );

  const onLogout = (): void => {
    removeSession();
    clearStorage();

    logout(apiConfig[ServiceTypes.SSO]);

    onRedirect();
  };

  return (
    <Layout>
      <Header />

      <Layout>
        <Sidebar
          items={menuItems}
          selectedKey={selectedKey}
          className="app-sidebar"
        >
          {chatConfig && (
            <Sidebar.Chats
              drawerOpen={chatDrawerOpen}
              onDrawerOpenChange={setChatDrawerOpen}
              icon={<Badge count={count}>{<svg.Chat />}</Badge>}
              label="Chat"
            >
              <CreateConnect
                config={chatConfig}
                onChangeCount={setCount}
                onClose={closeChatDrawer}
              />
            </Sidebar.Chats>
          )}
          {/* <Sidebar.Notifications /> */}
          <Sidebar.Actions
            items={[
              {
                key: "settings",
                title: "My profile",
                icon: <User />,
                onClick: (): void => history.push("/profile")
              },
              {
                key: "logout",
                title: "Logout",
                icon: <Icon type="logout" />,
                onClick: (): void => onLogout()
              }
            ]}
          />
          <Sidebar.Applications />
        </Sidebar>

        <Content className="app-content">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
