import React from "react";
import classNames from "classnames";
import { Button } from "antd";
import { IconButtonProps } from "./index";
import "./IconButton.css";

const IconButton: React.FC<IconButtonProps> = ({
  text,
  svgIcon,
  className,
  ...props
}) => {
  return (
    <Button {...props} className={classNames("btn icon-button", className)}>
      {svgIcon && svgIcon}
      {text}
    </Button>
  );
};

IconButton.defaultProps = {
  type: "primary"
};

export default IconButton;
