import { getNameAcronym } from "utils/strings";
import { OptionItem } from "./UserSelect";

// Normalize user option for select
// TODO: add typings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeUserOption = (users: any): OptionItem[] => {
  if (!users) {
    return [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return users.map((item: any) => {
    const user = Object.prototype.hasOwnProperty.call(item, "user")
      ? item.user
      : item;
    const title = `${user.first_name} ${user.last_name}`;
    const acronym = getNameAcronym(title);
    const className =
      !user.first_name && !user.last_name ? "user-select__no-name" : "";

    return {
      id: user.id,
      title,
      description: user.email,
      photo: user.photo,
      noPhotoTitle: acronym,
      className
    };
  });
};
