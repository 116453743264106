export enum UserRole {
  employee = "employee",
  admin = "admin"
}

export interface WithResults<T> {
  count?: number;
  current_page?: number;
  per_page?: number;
  total_pages?: number;
  results?: T[];
}
