import moment from "moment";

// User profile
import { TeamData, OrganizationUser } from "libs/interfaces/users";

export interface UserProps {
  id?: number;
  name: string;
  email: string;
  photo?: string;
  status?: string;
  sso_id?: string;
  is_active?: boolean;
}

interface UserTeams {
  id: number;
  name: string;
}

// User table
export interface User {
  id?: number;
  key: string;
  user: UserProps;
  last_login: string;
  job_title: string | null;
  role: string;
  teams: UserTeams[];
}

// Team table
export interface Team {
  id?: number;
  key: string;
  name: string;
  date_created: string;
  users: string;
}

// Normalize user object for table
export const normalizeUsers = (users: OrganizationUser[]): User[] =>
  users.map(({ id, role, user, status, teams, job_title }) => {
    const formattedDate = user.last_login
      ? moment(user.last_login).format("DD/MM/YYYY HH:mm")
      : "";

    const normalizedUser: User = {
      id,
      key: String(id),
      user: {
        id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        photo: user.photo || "",
        status,
        sso_id: user.sso_id,
        is_active: user.is_active
      },
      last_login: formattedDate,
      job_title,
      teams,
      role
    };

    return normalizedUser;
  });

export interface NormalizedTeam {
  key: number;
  id: number;
  name: string;
  date_created: string;
  users: number;
}

// Normalize team object for table
export function normalizeTeams(teams: TeamData[]): NormalizedTeam[] {
  if (!teams) {
    return [];
  }

  return teams.map(({ id, name, date_created, users }) => {
    const formattedDate = date_created
      ? moment(date_created).format("DD/MM/YYYY HH:mm")
      : "";
    const normalizedTeam: NormalizedTeam = {
      key: id,
      id,
      name,
      date_created: formattedDate,
      users
    };

    return normalizedTeam;
  });
}
